
function About() {
    return (
    <div>
    {/*Page Header Start*/}
  <section className="page-header">
    <div
      className="page-header__bg"
      style={{
        backgroundImage: "url(assets/images/resources/about-1-5.png)",objectFit:'cover',backgroundRepeat:'no-repeat'
      }}
    ></div>
    <div className="container">
      <div className="page-header__inner">
        <h2>About</h2>
        <div className="thm-breadcrumb__box">
          <ul className="thm-breadcrumb list-unstyled">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <span className="icon-arrow-left" />
            </li>
            <li>About</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  {/*Page Header End*/}
  {/*About Three Start */}
  <section className="">
    {/*About One Start */}
    <section className="about-one">
      <div className="about-one__shape-2 float-bob">
        <img src="assets/images/resources/about-1-3.jpg" alt="" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-xl-6">
            <div
              className="about-one__left wow slideInLeft"
              data-wow-delay="100ms"
              data-wow-duration="2500ms"
            >
              <div className="row" >
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="about-one__img-1">
                    <img src="assets/images/resources/about-1-1.jpg" alt=""/>
                    <div className="about-one__shape-1 float-bob-y" >
                      <img
                        src="assets/images/shapes/about-one-shape-1.png" alt="" height='100%'
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="about-one__img-2">
                    <img src="assets/images/resources/about-1-2.jpg" alt="" />
                    {/*<div class="about-one__award-box">
                                      <div class="about-one__award-icon">
                                          <span class="icon-award"></span>
                                      </div>
                                      <div class="about-one__count count-box">
                                          <h3 class="count-text" data-stop="20" data-speed="1500">00</h3>
                                          <span>+</span>
                                      </div>
                                      <p class="about-one__count-text">Winning award</p>
                                  </div>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="about-one__right">
              <div className="section-title text-left">
                <div className="section-title__tagline-box">
                  <span className="section-title__tagline">ABOUT US</span>
                </div>
                <h2 className="section-title__title">
                  Reliable best luxury fleet Professionals at Your Service
                </h2>
              </div>
              <p className="about-one__text">
            The most famous and elegant travel destination in the world is popular for its novelty and lushness that it provides. 
            To explore the real amusement of Dubai the need for a professional chauffeur service is a vital thing, that’s what *RIZ* for you. As our tagline indicates, one can realize that RIZ is the Epitome for anyone’s imagination on luxury.
            </p>
            <p className="about-one__text pt-2">
            RIZ Chauffeur was found on a vision of offering the best and unbeaten service by utilizing all available modern technologies and by following industry standards in every bit and keeping on updating in every moment.
            </p>
            <p className="about-one__text pt-2">
              We offer a diverse fleet of luxury vehicles to meet your
              transportation needs. Whether you need a taxicab, minivan, SUV, or
              VIP limo, we have it all. Our vehicles are regularly maintained to
              ensure a safe and comfortable ride for our clients.
            </p>
             
              <div className="about-one__btn-box mt-4">
                <a href="/about" className="about-one__btn thm-btn">
                  Read More
                  <span className="icon-arrow-left" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/*About One End */}
  </section>
  {/*About Three End */}
  {/*CTA Two Start*/}
  <section className="cta-two">
    <div
      className="cta-two__img-1"
      style={{
        backgroundImage: "url(assets/images/resources/cta-two-img-1.jpg)"
      }}
    />
    <div className="cta-two__shape-1 float-bob-x">
      <img src="assets/images/shapes/cta-two-shape-1.png" alt="" />
    </div>
    <div className="container">
      <div className="row">
        <div className="col-xl-6 col-lg-4" />
        <div className="col-xl-6 col-lg-8">
          <div className="cta-two__right">
            <h3 className="cta-two__title">Subscribe to our newsletter</h3>
            <p className="cta-two__text">
              It is a long established fact that a reader will be distracted by
              the readable
              <br /> content of a page when looking at its layout.
            </p>
            <form className="cta-two__contact-form">
              <div className="cta-two__contact-input-box">
                <input
                  type="email"
                  placeholder="Enter Your Email"
                  name="email"
                />
                <button type="submit" className="cta-two__contact-btn thm-btn">
                  Subscribe Now
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*CTA Two End*/}
  <div className="scroll-to-top">
    <svg className="scroll-top-inner" viewBox="-1 -1 102 102">
        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
    </svg>
    </div>

 {/* /.page-wrapper */}
 <div className="mobile-nav__wrapper">
    <div className="mobile-nav__overlay mobile-nav__toggler" />
    {/* /.mobile-nav__overlay */}
    <div className="mobile-nav__content">
      <span className="mobile-nav__close mobile-nav__toggler">
        <i className="fa fa-times" />
      </span>
      <div className="logo-box">
        <a href="/" style={{ paddingLeft: "0PX" }}>
          <img
            src="assets/images/resources/logosmall.png"
            height="80px"
            style={{ marginLeft: "-40px" }}
            alt=""
          />
        </a>
      </div>
      {/* /.logo-box */}
      <div className="mobile-nav__container" />
      {/* /.mobile-nav__container */}
      <ul className="mobile-nav__contact list-unstyled">
        <li>
          <i className="fa fa-envelope" />
          <a href="mailto:needhelp@packageName__.com">
            booking@rizchauffeur.com
          </a>
        </li>
        <li>
          <i className="fa fa-phone-alt" />
          <a href="tel:666-888-0000">+971 563847412</a>
        </li>
      </ul>
      {/* /.mobile-nav__contact */}
      <div className="mobile-nav__top">
        <div className="mobile-nav__social">
          <a href="#" className="fab fa-twitter" />
          <a href="#" className="fab fa-facebook-square" />
          <a href="#" className="fab fa-pinterest-p" />
          <a href="#" className="fab fa-instagram" />
        </div>
        {/* /.mobile-nav__social */}
      </div>
      {/* /.mobile-nav__top */}
    </div>
    {/* /.mobile-nav__content */}
  </div>
  {/* /.mobile-nav__wrapper */}
  
  <div className="scroll-to-top">
    <svg className="scroll-top-inner" viewBox="-1 -1 102 102">
        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
    </svg>
</div>
    </div>
    );
}
export default About;