import { Link } from "react-router-dom";

function Contact() {
    return (
        <div>
          
  {/*Page Header Start*/}


  <section className="page-header" 
   style={{
    backgroundImage:
      "url(assets/images/contact/contact-us.jpg)",
    backgroundSize: "100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    height: 275, 

  }}
  alt="Logistifie contact us"
  >
    <div
      className="page-header__bg"
      style={{
        backgroundImage:
          "url(assets/images/contact/contact-us.jpg)",
        backgroundSize: "100%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        height: 375, display:'none'
      }}
      alt="Logistifie contact us"
    ></div>
  

    <div className="container" style={{display:'none'}}>
      <div className="page-header__inner">
        <h2>Contact</h2>
        <div className="thm-breadcrumb__box">
          <ul className="thm-breadcrumb list-unstyled">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <span className="icon-arrow-left" />
            </li>
            <li>Contact</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  {/*Page Header End*/}
   {/*Contact Three End*/}
   

   <section className=" pt-5" style={{display:'none'}} >
    <div className="container">
      <div className="row">
        {/*Contact Three Single Start*/}
        <div className="col-xl-4 col-lg-4">
          <div className="contact-three__single">
            <div className="contact-three__icon">
              <span className="icon-location" />
            </div>
            <h3 className="contact-three__title">Location</h3>
            <p className="contact-three__text">
              Office #:718,Block-B, Business Vilage,Dubai-UAE{" "}
            </p>
          </div>
        </div>
        {/*Contact Three Single End*/}
        {/*Contact Three Single Start*/}
        <div className="col-xl-4 col-lg-4">
          <div className="contact-three__single">
            <div className="contact-three__icon">
              <span className="icon-call" />
            </div>
            <h3 className="contact-three__title">Phone Number</h3>
            <p className="contact-three__text">
              <a href="tel:6295550129">+971 563847412</a>
            </p>
          </div>
        </div>
        {/*Contact Three Single End*/}
        {/*Contact Three Single Start*/}
        <div className="col-xl-4 col-lg-4">
          <div className="contact-three__single">
            <div className="contact-three__icon">
              <span className="icon-email" />
            </div>
            <h3 className="contact-three__title">E-mail</h3>
            <p className="contact-three__text">
              <a href="mailto:michael.mitc@example.com">
                booking@rizchauffeur.com
              </a>
            </p>
          </div>
        </div>
        {/*Contact Three Single End*/}
      </div>
    </div>
  </section>
  {/*Contact Three End*/}
  {/*Contact Two Start*/}
  <section className="pt-5" style={{backgroundColor:'white' }}>
  <div className="col-md-12"  >
  <div className="al_benefits" style={{ padding: 0 }}>
    <div className="container">
      <div className="al_our_services" style={{ padding: 0 }} />
      <div className="row">
        <div className="col-lg-3 col-md-6 al_benefits_outer">
          <div className="al_benefits_list_info">
            <h1>Location</h1>
            <p>
            Office #:718,Block-B,
              <br />
              Business Vilage,Dubai-UAE
            </p>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 al_benefits_outer">
          <div className="al_benefits_list_info">
            <h1>Phones</h1>
            <p>+971 5638 47412</p>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 al_benefits_outer">
          <div className="al_benefits_list_info">
            <h1>Email</h1>
            <p>booking@rizchauffeur.com</p>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 al_benefits_outer">
          <div className="al_benefits_list_info">
            <h1>Working Hours</h1>
            <p> 24 x 7</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
    <div
      className="container"
      style={{ backgroundColor: "white" }}
    >


      <div className="section-title text-center row al_formWrap" >
      <div className="col-md-6">
        <div className="section-title__tagline-box alMail" style={{border:'1px solid black',
           padding: "50px 50px 80px"
        }}>
        <h2 className="" style={{color:'black', paddingBottom:'20px'}}>Get In Touch </h2>
        <div className="contact-two__inner">
        <form
          className="contact-form-validated contact-two__form"
          action="assets/inc/sendemail.php"
          method="post"
          noValidate="novalidate"
        >
          <div className="row">
            <div className="col-xl-12 col-lg-6">
              <div className="contact-two__input-box">
                <input type="text" name="name" placeholder="Name" required="" />
              </div>
            </div>
            <div className="col-xl-12 col-lg-6">
              <div className="contact-two__input-box">
                <input
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  required=""
                />
              </div>
            </div>
            <div className="col-xl-12 col-lg-6">
              <div className="contact-two__input-box">
                <input
                  type="text"
                  name="Phone"
                  placeholder="Phone Number"
                  required=""
                />
              </div>
            </div>
            <div className="col-xl-12">
              <div className="contact-two__input-box text-message-box">
                <textarea
                  name="message"
                  placeholder="Message here.."
                  defaultValue={""}
                />
              </div>
              <div className="contact-two__btn-box">
                <button type="submit" className="thm-btn contact-two__btn">
                  Appointment Now
                  <span className="icon-paper-plan" />
                </button>
              </div>
            </div>
          </div>
        </form>
        <div className="result" />
      </div>
        </div>
       </div>
       <div className="col-md-6 alAlignCenter">
  <div className="alMail">
    <img
      alt="Contact"
      loading="lazy"
      width={472}
      height={202}
      decoding="async"
      data-nimg={1}
      src="assets/images/fleet/png/c_1.png"
      style={{ color: "transparent" }}
    />
  </div>
</div>

      </div>
     
    </div>
  </section>
  {/*Contact Two End*/}
 
  {/*Google Map Start*/}
  <section className="google-map-one">
    <div className="container">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.3799040595804!2d55.32462817489244!3d25.25780262924429!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5cda223df2cd%3A0x687e9dfb4888e0f4!2sBusiness%20Village%20-%20Block%20A!5e0!3m2!1sen!2sae!4v1723799137643!5m2!1sen!2sae"
        width="100%"
        height={450}
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </div>
  </section>
  {/*Google Map End*/}
 {/* /.page-wrapper */}
 <div className="mobile-nav__wrapper">
    <div className="mobile-nav__overlay mobile-nav__toggler" />
    {/* /.mobile-nav__overlay */}
    <div className="mobile-nav__content">
      <span className="mobile-nav__close mobile-nav__toggler">
        <i className="fa fa-times" />
      </span>
      <div className="logo-box">
        <a href="/" style={{ paddingLeft: "0PX" }}>
          <img
            src="assets/images/resources/logosmall.png"
            height="80px"
            style={{ marginLeft: "-40px" }}
            alt=""
          />
        </a>
      </div>
      {/* /.logo-box */}
      <div className="mobile-nav__container" />
      {/* /.mobile-nav__container */}
      <ul className="mobile-nav__contact list-unstyled">
        <li>
          <i className="fa fa-envelope" />
          <a href="mailto:needhelp@packageName__.com">
            booking@rizchauffeur.com
          </a>
        </li>
        <li>
          <i className="fa fa-phone-alt" />
          <a href="tel:666-888-0000">+971 563847412</a>
        </li>
      </ul>
      {/* /.mobile-nav__contact */}
      <div className="mobile-nav__top">
        <div className="mobile-nav__social">
          <a href="#" className="fab fa-twitter" />
          <a href="#" className="fab fa-facebook-square" />
          <a href="#" className="fab fa-pinterest-p" />
          <a href="#" className="fab fa-instagram" />
        </div>
        {/* /.mobile-nav__social */}
      </div>
      {/* /.mobile-nav__top */}
    </div>
    {/* /.mobile-nav__content */}
  </div>
  {/* /.mobile-nav__wrapper */}
  
  <div className="scroll-to-top">
    <svg className="scroll-top-inner" viewBox="-1 -1 102 102">
        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
    </svg>
</div>
        </div>
    )
};
export default Contact;