import { Link } from "react-router-dom";
import React,{useEffect,useState,useRef} from 'react';
function ChauffeurServices() {
  useEffect(() => {
    window.scroll(0, 0);
    
},[]);
    return (
        <div className="page-wrapper" style={{backgroundColor:'white'}}>
        {/*Page Header Start*/}
<section className="page-header">
<div
  className="page-header__bg"
  style={{
    backgroundImage: "url(assets/images/backgrounds/slider-1-2.jpg)"
  }}
></div>
<div className="container">
  <div className="page-header__inner">
    <h2>Chauffeur Services</h2>
    <div className="thm-breadcrumb__box">
      <ul className="thm-breadcrumb list-unstyled">
        <li>
          <a href="/">Home</a>
        </li>
        <li>
          <span className="icon-arrow-left" />
        </li>
        <li>Chauffeur Services</li>
      </ul>
    </div>
  </div>
</div>
</section>

<>
  {/*Service Details Start*/}
  <section className="" style={{paddingTop:'50px'}}>
    <div className="container">
      <div className="row">
        <div className="col-xl-8 col-lg-7">
          <div className="service-details__left">
            <div className="service-details__img-one">
              <img
                src="assets/images/services/chauffeurservices-1.jpg"
                alt=""
              />
            </div>
            <div className="service-details__content-one">
              <h4 className="service-details__title-one">Chauffeur Services</h4>
              <p className="service-details__text-1">
              Riz aims to ensure that every moment of your trip is unforgettable and wonderful. The experienced chauffeurs' smart driving skills, politeness, and dedication guarantee a safe and luxurious service for you.
              </p>
              <p className="service-details__text-1">
               We guarantee the smooth and punctual transportation to your destination, even if you are unfamiliar with the area, languages, 
               and customs. The diligence, integrity, and dedication of our drivers enable them to adapt to varying situations 
               and overcome challenges in an unfamiliar setting.
              </p>
              <p className="service-details__text-1">
               When you visit Dubai alone or with your family, you can discover numerous incredible and captivating destinations. Nowhere else is as ideal for meeting clients and having business discussions as this place. Riz chauffeur service assists in guiding you through the city, offering luxurious cars with chauffeurs for business meetings and events. Choose from a wide range of fleets and our 24/7 operational team will respond to all your inquiries. Receive the quotation within 15 minutes.
              </p>
              <p className="service-details__text-1">
               Experience a pleasant journey with Riz's top chauffeur service in Dubai at a cost-effective rate. Our operational team will ensure the chauffeur arrives punctually for pick-up and drop-off to avoid any inconvenience, and our skilled chauffeurs are familiar with all the main routes in Dubai to assist you in reaching any destination effortlessly.
              </p>
              <h4 className="service-details__title-one">Hire chauffeur service in Dubai</h4>
              <p className="service-details__text-1">
              Dubai is known as a top destination for both business and leisure travelers due to its luxurious offerings. Luxury transportation options are available for visitors to experience while exploring the city.
              </p>
              <p className="service-details__text-1">         
              Our services are priced competitively with no hidden fees, making exotic and luxury cars affordable for you. Rates include all services with no extra fees. There are several reasons to choose our services:
              </p>
              <p className="service-details__text-1">         
              Skilled drivers - The chauffeurs available to you are highly trained and consistently on time to ensure you experience maximum comfort and tranquility during your journey. Our drivers who are able to communicate in English and wear suitable attire. You can trust us, our drivers are all trained and certified from authorized centers, ensuring your safety.
              </p>
              <p className="service-details__text-1">         
              We have a variety of fleets available for you to select the ideal car from.
              </p>
            </div>
           
          </div>
        </div>
        <div className="col-xl-4 col-lg-5">
          <div className="service-details__sidebar">
            <div className="service-details__service-list-box">
              <h4 className="service-details__sidebar-title">
                Our All Service
              </h4>
              <ul className="service-details__service-list list-unstyled">
                <li className="active">
                  <Link to="/airporttransfer">
                  Airport Transfer
                    <span className="icon-right-arrow" />
                  </Link>
                </li>
                <li>
                  <Link to="/busrental">
                  Bus | Minibus | Van rental
                    <span className="icon-right-arrow" />
                  </Link>
                </li>
                <li>
                  <Link to="/chauffeurservices">
                  Chauffeur Service
                    <span className="icon-right-arrow" />
                  </Link>
                </li>
                <li>
                  <Link to="/limousineservice">
Limousine Service Dubai
                    <span className="icon-right-arrow" />
                  </Link>
                </li>
                <li>
                  <Link to="/hourlyrateservice">
                  Hourly Rate Service
                    <span className="icon-right-arrow" />
                  </Link>
                </li>
                <li>
                  <Link to="/groundtransportation">
                  Ground Transportation
                    <span className="icon-right-arrow" />
                  </Link>
                </li>
              </ul>
            </div>
            <div className="service-details__need-help">
              <div className="service-details__need-help-icon">
                <span className="icon-call" />
              </div>
              <p className="service-details__need-help-sub-title">Need help?</p>
              <h5 className="service-details__need-help-number">
                <a href="tel:8085550111">+971 563847412</a>
              </h5>
              <p className="service-details__need-help-text">
              Email : booking@rizchauffeur.com
              </p>
            </div>
            <div className="service-details__brochure-box" style={{display:'none'}}>
              <h5 className="service-details__brochure-box-title" style={{display:'none'}}>Brochure</h5>
              <ul className="list-unstyled service-details__brochure-list">
                <li>
                  <div className="icon">
                    <span className="icon-file" />
                  </div>
                  <div className="content">
                    <h5>
                      <a href="#">Download Brochure</a>
                    </h5>
                    <p>
                      orem Ipsum is simply is dumiomy is tex Lorem Ipsum is
                      simply is ou our
                    </p>
                  </div>
                </li>
                <li>
                  <div className="icon">
                    <span className="icon-file" />
                  </div>
                  <div className="content">
                    <h5>
                      <a href="#">Company Details</a>
                    </h5>
                    <p>
                      orem Ipsum is simply is dumiomy is tex Lorem Ipsum is
                      simply is ou our
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </section>
  <br></br>
  {/*Service Details End*/}
</>

 {/* /.page-wrapper */}
 <div className="mobile-nav__wrapper">
    <div className="mobile-nav__overlay mobile-nav__toggler" />
    {/* /.mobile-nav__overlay */}
    <div className="mobile-nav__content">
      <span className="mobile-nav__close mobile-nav__toggler">
        <i className="fa fa-times" />
      </span>
      <div className="logo-box">
        <a href="/" style={{ paddingLeft: "0PX" }}>
          <img
            src="assets/images/resources/logosmall.png"
            height="80px"
            style={{ marginLeft: "-40px" }}
            alt=""
          />
        </a>
      </div>
      {/* /.logo-box */}
      <div className="mobile-nav__container" />
      {/* /.mobile-nav__container */}
      <ul className="mobile-nav__contact list-unstyled">
        <li>
          <i className="fa fa-envelope" />
          <a href="mailto:needhelp@packageName__.com">
            booking@rizchauffeur.com
          </a>
        </li>
        <li>
          <i className="fa fa-phone-alt" />
          <a href="tel:666-888-0000">+971 563847412</a>
        </li>
      </ul>
      {/* /.mobile-nav__contact */}
      <div className="mobile-nav__top">
        <div className="mobile-nav__social">
          <a href="#" className="fab fa-twitter" />
          <a href="#" className="fab fa-facebook-square" />
          <a href="#" className="fab fa-pinterest-p" />
          <a href="#" className="fab fa-instagram" />
        </div>
        {/* /.mobile-nav__social */}
      </div>
      {/* /.mobile-nav__top */}
    </div>
    {/* /.mobile-nav__content */}
  </div>
  {/* /.mobile-nav__wrapper */}
  
  <div className="scroll-to-top">
    <svg className="scroll-top-inner" viewBox="-1 -1 102 102">
        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
    </svg>
</div>
</div>
    )
};
export default ChauffeurServices;