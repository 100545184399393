
import React from 'react'; 
import './App.css';
import Header from './components/header';
import Footer from './components/footer';
import Index from './pages/index';
import About from './pages/about'
import OurFleet from './pages/ourfleet'
import Contact from './pages/contact'
import Gallery from './pages/gallery'
import Services from './pages/services'
import BookRizChauffeur from './pages/bookrizchauffeur'
import AirportTransfer from './services/airporttransfer'
import Terms from './pages/terms'
import PrivacyPolicy from './pages/privacypolicy'
import BusRental from './services/busrental';
import ChauffeurServices from './services/chauffeurservices'
import LimousineServices from './services/limousineservice'
import GroundTransportation from './services/groundtransportation'
import HourlyServices from './services/hourlyrateservice'
import Blog from './pages/blog'
import EventTransportation from './services/eventtransportation';
//import CorporateTransportation from './services/corporatetransportation';
import CityTour from './services/citytour';
import PortTransfer from './services/porttransfer';
import IntercityTransfer from './services/intercitytransfer';
import DesertSafari from './services/desertsafari';
import StaffTransportation from './services/stafftransportation';
import LimoRideDubai from './services/limoridedubai';
import RollsRoycePhantom from './ourfleet/rollsroycephantom';
import RollsRoyceGhost from './ourfleet/rollsroyceghost';
import RollsRoyceCullinan from './ourfleet/rollsroycecullinan';
import BentleyFlyingSpur from './ourfleet/bentleyflyingspur';
import MercedesSClass from './ourfleet/mercedessclass';
import BMW7series from './ourfleet/bmw7series';
import AudiA8 from './ourfleet/audia8';
import CadillacEscalade from './ourfleet/cadillacescalade';
import GMCYukon from './ourfleet/gmcyukon';

import {HashRouter,
  Routes,
  Route,
} from "react-router-dom";
import StrechLimousine from './ourfleet/strechlimousine';
import TeslaModelY from './ourfleet/teslamodely';
import TeslaModel3 from './ourfleet/teslamodel3';
import Seater50LuxuryBus from './ourfleet/seater50luxurybus';
import Seater35LuxuryBus from './ourfleet/seater35luxurybus';
import ToyotaCoaster from './ourfleet/toyotacoaster';
import ToyotaHiace from './ourfleet/toyotahiace';
import MercedesSprinter from './ourfleet/mercedessprinter';
import AudiA6 from './ourfleet/audia6';
import BMW5Series from './ourfleet/bmw5series';
import MercedesEClass from './ourfleet/mercedeseclass';
import LexusES350 from './ourfleet/lexuses350';
import ToyotaGranvia from './ourfleet/toyotagranvia';
import ToyotaPrevia from './ourfleet/toyotaprevia';
import HondaOdyssey from './ourfleet/hondaodyeesy';
import MercedesVClass from './ourfleet/mercedesvclass';

function App() {
  

  return (
    <>
     <div className="page-wrapper">
     
          <Header></Header>
          <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/about" element={<About />} />
          <Route path="/ourfleet" element={<OurFleet />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/services" element={<Services />} />
          <Route path="/bookrizchauffeur" element={<BookRizChauffeur />} />
          <Route path="/airporttransfer" element={<AirportTransfer />} />
          <Route path="/busrental" element={<BusRental />} />
          <Route path="/chauffeurservices" element={<ChauffeurServices />} />
          <Route path="/limousineservice" element={<LimousineServices />} />
          <Route path="/hourlyrateservice" element={<HourlyServices />} />
          <Route path="/groundtransportation" element={<GroundTransportation />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/eventtransportation" element={<EventTransportation />} />
          
          <Route path="/citytour" element={<CityTour />} />
          <Route path="/porttransfer" element={<PortTransfer />} />
          <Route path="/intercitytransfer" element={<IntercityTransfer />} />

          <Route path="/desertsafari" element={<DesertSafari />} />
          <Route path="/stafftransportation" element={<StaffTransportation />} />
          <Route path="/limoridedubai" element={<LimoRideDubai />} />
          <Route path="/rollsroycephantom" element={<RollsRoycePhantom />} />
          <Route path="/rollsroyceghost" element={<RollsRoyceGhost />} />
          <Route path="/rollsroycecullinan" element={<RollsRoyceCullinan />} />
          <Route path="/bentleyflyingspur" element={<BentleyFlyingSpur />} />
          <Route path="/mercedessclass" element={<MercedesSClass />} />
          <Route path="/bmw7series" element={<BMW7series />} />
          <Route path="/audia8" element={<AudiA8 />} />
          <Route path="/cadillacescalade" element={<CadillacEscalade />} />
          <Route path="/gmcyukon" element={<GMCYukon />} />
          <Route path="/strechlimousine" element={<StrechLimousine />} />
          <Route path="/teslamodely" element={<TeslaModelY />} />
          <Route path="/teslamodel3" element={<TeslaModel3 />} />
          <Route path="/seater50luxurybus" element={<Seater50LuxuryBus />} />
          <Route path="/seater35luxurybus" element={<Seater35LuxuryBus />} />
          <Route path="/toyotacoaster" element={<ToyotaCoaster />} />
          <Route path="/toyotahiace" element={<ToyotaHiace />} />
          <Route path="/mercedessprinter" element={<MercedesSprinter />} />
          <Route path="/audia6" element={<AudiA6 />} />
          <Route path="/bmw5series" element={<BMW5Series />} />
          <Route path="/mercedeseclass" element={<MercedesEClass />} />
          <Route path="/lexuses350" element={<LexusES350 />} />
          <Route path="/toyotagranvia" element={<ToyotaGranvia />} />
          <Route path="/toyotaprevia" element={<ToyotaPrevia />} />
          <Route path="/hondaodyeesy" element={<HondaOdyssey />} />
          <Route path="/mercedesvclass" element={<MercedesVClass />} />
          </Routes>
          <Footer></Footer>
</div>
    </>
  );
}

export default App;
