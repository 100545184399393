import { Link } from "react-router-dom";
import React,{useEffect,useState,useRef} from 'react';
function LimousineServices() {
  useEffect(() => {
    window.scroll(0, 0);
    
},[]);
    return (
        <div className="page-wrapper" style={{backgroundColor:'white'}}>
        {/*Page Header Start*/}
<section className="page-header">
<div
  className="page-header__bg"
  style={{
    backgroundImage: "url(assets/images/backgrounds/slider-1-2.jpg)"
  }}
></div>
<div className="container">
  <div className="page-header__inner">
    <h2>Limousine Services</h2>
    <div className="thm-breadcrumb__box">
      <ul className="thm-breadcrumb list-unstyled">
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <span className="icon-arrow-left" />
        </li>
        <li>Limousine Services</li>
      </ul>
    </div>
  </div>
</div>
</section>

<>
  {/*Service Details Start*/}
  <section className="" style={{paddingTop:'50px'}}>
    <div className="container">
      <div className="row">
        <div className="col-xl-8 col-lg-7">
          <div className="service-details__left">
            <div className="service-details__img-one">
              <img
                src="assets/images/services/limousineservices-3.jpg"
                alt=""
              />
            </div>
            <div className="service-details__content-one">
              <h4 className="service-details__title-one">Limousine Services</h4>
              <p className="service-details__text-1">
              Riz Chauffeurs offers limousine transportation for various events such as weddings, business conferences, and other gatherings to ensure that your special moments shine with a grand arrival. You will experience a pleasant trip where you can participate in an online conversation or even organize one inside the limousine, equipped with exceptional amenities and luxurious surroundings resembling a five-star environment.
              </p>
              <h4 className="service-details__title-one">STRETCH LIMOUSINE FLEET FOR EVENTS</h4>
              <p className="service-details__text-1">
              Select from our selection of luxurious limousine services to make your trip unforgettable. Our skilled and experienced drivers ensure you will be picked up and dropped off punctually. Make sure you show up to special occasions with sophistication and grace by reserving a vehicle with us.
              </p>
              <p className="service-details__text-1">
              We provide luxurious limousine cards with premium features that offer exceptional comfort. Upgrading your travel experience in Abu Dhabi is possible through features such as LCD screens, cozy seats, stylish lighting, seamless journeys, privacy, and Wi-Fi access.
              </p>
            </div>
            <h4 className="WE ARE CAPABLE OF MATCHING ANY PRICE">STRETCH LIMOUSINE FLEET FOR EVENTS</h4>
            <div style={{color:'goldenrod'}}><a href="" style={{cursor:'pointer'}}>Limousine Service in  Dubai</a></div>
            <p className="service-details__text-1">
            We assure you that our hourly rates cannot be beaten by any other stretch limo companies in Dubai, and our experienced professional staff will help you plan the route for city sightseeing.
              </p>
              <p className="service-details__text-1">
              Dubai is known as one of the top luxurious cities for tourists, with a reputation for its vibrant nightlife, extravagant locations, and lavish lifestyle.
              </p>
              <p className="service-details__text-1">
              Our high-end limousine service will make you feel like a VIP. Whether your group is big or small, we offer luxury cars or minibuses from trusted brands like Mercedes-Benz, BMW, and Audi to accommodate your needs. In case of flight delays or heavy traffic, the cost of your limousine and chauffeur will remain the same, ensuring our transportation service is cost-effective. You can reserve a vehicle for however long you need, whether it's just a few hours or an extended period of time.
              </p>
              <p className="service-details__text-1">
              For maximum comfort during your trip, consider renting an affordable limousine in the city. You are mistaken if you believe that only the wealthy and well-known can access limousine services. Hiring a limousine from Dubai is definitely a great way to add glamour and excitement to any event or occasion. When you travel in a luxurious vehicle with modern amenities, the journey becomes incredibly thrilling.
              </p>
          </div>
        </div>
        <div className="col-xl-4 col-lg-5">
          <div className="service-details__sidebar">
            <div className="service-details__service-list-box">
              <h4 className="service-details__sidebar-title">
                Our All Service
              </h4>
              <ul className="service-details__service-list list-unstyled">
                <li className="active">
                  <Link to="/airporttransfer">
                  Airport Transfer
                    <span className="icon-right-arrow" />
                  </Link>
                </li>
                <li>
                  <Link to="/busrental">
                  Bus | Minibus | Van rental
                    <span className="icon-right-arrow" />
                  </Link>
                </li>
                <li>
                  <Link to="/chauffeurservices">
                  Chauffeur Service
                    <span className="icon-right-arrow" />
                  </Link>
                </li>
                <li>
                  <Link to="/limousineservice">
Limousine Service Dubai
                    <span className="icon-right-arrow" />
                  </Link>
                </li>
                <li>
                  <Link to="/hourlyrateservice">
                  Hourly Rate Service
                    <span className="icon-right-arrow" />
                  </Link>
                </li>
                <li>
                  <Link to="/groundtransportation">
                  Ground Transportation
                    <span className="icon-right-arrow" />
                  </Link>
                </li>
              </ul>
            </div>
            <div className="service-details__need-help">
              <div className="service-details__need-help-icon">
                <span className="icon-call" />
              </div>
              <p className="service-details__need-help-sub-title">Need help?</p>
              <h5 className="service-details__need-help-number">
                <a href="tel:8085550111">+971 563847412</a>
              </h5>
              <p className="service-details__need-help-text">
              Email : booking@rizchauffeur.com
              </p>
            </div>
            <div className="service-details__brochure-box" style={{display:'none'}}>
              <h5 className="service-details__brochure-box-title">Brochure</h5>
              <ul className="list-unstyled service-details__brochure-list">
                <li>
                  <div className="icon">
                    <span className="icon-file" />
                  </div>
                  <div className="content">
                    <h5>
                      <a href="#">Download Brochure</a>
                    </h5>
                    <p>
                      orem Ipsum is simply is dumiomy is tex Lorem Ipsum is
                      simply is ou our
                    </p>
                  </div>
                </li>
                <li>
                  <div className="icon">
                    <span className="icon-file" />
                  </div>
                  <div className="content">
                    <h5>
                      <a href="#">Company Details</a>
                    </h5>
                    <p>
                      orem Ipsum is simply is dumiomy is tex Lorem Ipsum is
                      simply is ou our
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </section>
  <br></br>
  {/*Service Details End*/}
</>

 {/* /.page-wrapper */}
 <div className="mobile-nav__wrapper">
    <div className="mobile-nav__overlay mobile-nav__toggler" />
    {/* /.mobile-nav__overlay */}
    <div className="mobile-nav__content">
      <span className="mobile-nav__close mobile-nav__toggler">
        <i className="fa fa-times" />
      </span>
      <div className="logo-box">
        <a href="/" style={{ paddingLeft: "0PX" }}>
          <img
            src="assets/images/resources/logosmall.png"
            height="80px"
            style={{ marginLeft: "-40px" }}
            alt=""
          />
        </a>
      </div>
      {/* /.logo-box */}
      <div className="mobile-nav__container" />
      {/* /.mobile-nav__container */}
      <ul className="mobile-nav__contact list-unstyled">
        <li>
          <i className="fa fa-envelope" />
          <a href="mailto:needhelp@packageName__.com">
            booking@rizchauffeur.com
          </a>
        </li>
        <li>
          <i className="fa fa-phone-alt" />
          <a href="tel:666-888-0000">+971 563847412</a>
        </li>
      </ul>
      {/* /.mobile-nav__contact */}
      <div className="mobile-nav__top">
        <div className="mobile-nav__social">
          <a href="#" className="fab fa-twitter" />
          <a href="#" className="fab fa-facebook-square" />
          <a href="#" className="fab fa-pinterest-p" />
          <a href="#" className="fab fa-instagram" />
        </div>
        {/* /.mobile-nav__social */}
      </div>
      {/* /.mobile-nav__top */}
    </div>
    {/* /.mobile-nav__content */}
  </div>
  {/* /.mobile-nav__wrapper */}
  
  <div className="scroll-to-top">
    <svg className="scroll-top-inner" viewBox="-1 -1 102 102">
        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
    </svg>
</div>
</div>
    )
};
export default LimousineServices;