import { Link,useNavigate} from 'react-router-dom';
import React,{useEffect,useState,useRef} from 'react';
function BMW5Series() {
  useEffect(() => {
    window.scroll(0, 0);    
    
},[]);
const navigate = useNavigate()
const btnBookRollsRoycePhantom =(vehicle,seats,baggage)=>
  {
    navigate("/bookrizchauffeur", {state: {
      oneWayVehicle:vehicle,seats:seats,baggage:baggage
    }});
  }
    return (
        
        <div className="page-wrapper" style={{backgroundColor:'white'}}>
            {/*Page Header Start*/}
  <section className="page-header">
    <div
      className="page-header__bg"
      style={{
        backgroundImage: "url(assets/images/fleet/img/bmw5_1.jpg)"
      }}
    ></div>
    <div className="container">
      <div className="page-header__inner">
        <h2>BMW 5 Series</h2>
        <div className="thm-breadcrumb__box">
          <ul className="thm-breadcrumb list-unstyled">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <span className="icon-arrow-left" />
            </li>
            <li>BMW 5 Series</li>
          </ul>
        </div>
      </div>
    </div>
  </section>

  <>

  {/*Service Details Start*/}
  <section className="" style={{paddingTop:'50px'}}>
  
 
    <div className="container">
        
      <div className="row">
        <div className="col-xl-8 col-lg-7">
        <div  style={{paddingLeft:'0px', borderBottom:'2px solid goldenrod'}}>
    <span className='twelve'>BMW 5 Series</span>
   
  </div>
  <p className='fleetsViewMore'><b>BMW</b></p>
  <ul ><p>Luxury Class</p><p><span>3 Seats</span> || <span>Max. 2 Baggage</span>
  <a className="al_readmore" 
  style={{float:'right', borderRadius:'13px', cursor:'pointer'}}  onClick={() => btnBookRollsRoycePhantom('BMW 5 Series', '3 Seats','max. 2 Baggage')}>
      <span className="lte-btn-before" />
      Book Now
      <span className="lte-btn-after" />
    </a>
  </p></ul>
          <div className="service-details__left">
            <div className="service-details__img-one">
              <img
                src="assets/images/fleet/png/c_16.png" style={{width:'50%'}}
                alt=""
              />
            </div>
            <div className="service-details__content-one">
              <h4 className="service-details__title-one">BMW 5 Series</h4>
              <p className="service-details__text-1">
              The night sky that belongs to you personally. The brand's famous Starlight Headliner turns the ceiling into a captivating cityscape. Rolls-Royce craftsmen have the ability to personalize the celestial display by creating unique constellations in vibrant colors. With Ghost, there are no limits to the imagination.
              </p>
            </div>
           
          </div>
        </div>
        <div className="col-xl-4 col-lg-5">
          <div className="service-details__sidebar">
            <div className="service-details__service-list-box">
              <h4 className="service-details__sidebar-title">
                Our All Service
              </h4>
              <ul className="service-details__service-list list-unstyled">
                <li className="active">
                  <Link to="/airporttransfer">
                  Airport Transfer
                    <span className="icon-right-arrow" />
                  </Link>
                </li>
                <li>
                  <Link to="/busrental">
                  Bus | Minibus | Van rental
                    <span className="icon-right-arrow" />
                  </Link>
                </li>
                <li>
                  <Link to="/chauffeurservices">
                  Chauffeur Service
                    <span className="icon-right-arrow" />
                  </Link>
                </li>
                <li>
                  <Link to="/limousineservice">
Limousine Service Dubai
                    <span className="icon-right-arrow" />
                  </Link>
                </li>
                <li>
                  <Link to="/hourlyrateservice">
                    Hourly Rate Service
                    <span className="icon-right-arrow" />
                  </Link>
                </li>
                <li>
                  <Link to="/groundtransportation">
                  Ground Transportation
                    <span className="icon-right-arrow" />
                  </Link>
                </li>
              </ul>
            </div>
            <div className="service-details__need-help">
              <div className="service-details__need-help-icon">
                <span className="icon-call" />
              </div>
              <p className="service-details__need-help-sub-title">Need help?</p>
              <h5 className="service-details__need-help-number">
                <a href="tel:8085550111">+971 563847412</a>
              </h5>
              <p className="service-details__need-help-text">
                Email : booking@rizchauffeur.com
              </p>
            </div>
            <div className="service-details__brochure-box" style={{display:'none'}}>
              <h5 className="service-details__brochure-box-title">Brochure</h5>
              <ul className="list-unstyled service-details__brochure-list">
                <li>
                  <div className="icon">
                    <span className="icon-file" />
                  </div>
                  <div className="content">
                    <h5>
                      <a href="#">Download Brochure</a>
                    </h5>
                    <p>
                      orem Ipsum is simply is dumiomy is tex Lorem Ipsum is
                      simply is ou our
                    </p>
                  </div>
                </li>
                <li>
                  <div className="icon">
                    <span className="icon-file" />
                  </div>
                  <div className="content">
                    <h5>
                      <a href="#">Company Details</a>
                    </h5>
                    <p>
                      orem Ipsum is simply is dumiomy is tex Lorem Ipsum is
                      simply is ou our
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </section>
  <br></br>
  {/*Service Details End*/}
</>
    {/* /.page-wrapper */}
 <div className="mobile-nav__wrapper">
    <div className="mobile-nav__overlay mobile-nav__toggler" />
    {/* /.mobile-nav__overlay */}
    <div className="mobile-nav__content">
      <span className="mobile-nav__close mobile-nav__toggler">
        <i className="fa fa-times" />
      </span>
      <div className="logo-box">
        <a href="/" style={{ paddingLeft: "0PX" }}>
          <img
            src="assets/images/resources/logosmall.png"
            height="80px"
            style={{ marginLeft: "-40px" }}
            alt=""
          />
        </a>
      </div>
      {/* /.logo-box */}
      <div className="mobile-nav__container" />
      {/* /.mobile-nav__container */}
      <ul className="mobile-nav__contact list-unstyled">
        <li>
          <i className="fa fa-envelope" />
          <a href="mailto:needhelp@packageName__.com">
            booking@rizchauffeur.com
          </a>
        </li>
        <li>
          <i className="fa fa-phone-alt" />
          <a href="tel:666-888-0000">+971 563847412</a>
        </li>
      </ul>
      {/* /.mobile-nav__contact */}
      <div className="mobile-nav__top">
        <div className="mobile-nav__social">
          <a href="#" className="fab fa-twitter" />
          <a href="#" className="fab fa-facebook-square" />
          <a href="#" className="fab fa-pinterest-p" />
          <a href="#" className="fab fa-instagram" />
        </div>
        {/* /.mobile-nav__social */}
      </div>
      {/* /.mobile-nav__top */}
    </div>
    {/* /.mobile-nav__content */}
  </div>
  {/* /.mobile-nav__wrapper */}
  
  <div className="scroll-to-top">
    <svg className="scroll-top-inner" viewBox="-1 -1 102 102">
        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
    </svg>
</div>
        </div>
    )
};
export default BMW5Series