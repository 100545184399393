import { Link,useNavigate} from 'react-router-dom';
import React,{useEffect} from 'react';
function OurFleet() {

  const navigate = useNavigate()

  const btnRollsRoycePhantom =(link)=>
    {
      navigate("/" + link);
    }
    const btnBookRollsRoycePhantom =(vehicle,seats,baggage)=>
      {
        navigate("/bookrizchauffeur", {state: {
          oneWayVehicle:vehicle,seats:seats,baggage:baggage
        }});
      }
    return (
        
        <div style={{backgroundColor:'white'}}>
            {/*Page Header Start*/}
  <section className="page-header">
    <div
      className="page-header__bg"
      style={{
        backgroundImage: "url(assets/images/resources/about-1-5.png)"
      }}
    ></div>
    <div className="container">
      <div className="page-header__inner">
        <h2>Our Fleet</h2>
        <div className="thm-breadcrumb__box">
          <ul className="thm-breadcrumb list-unstyled">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <span className="icon-arrow-left" />
            </li>
            <li>Our Fleet</li>
          </ul>
        </div>
      </div>
    </div>
  </section>

  {/*Services Two Start */}
  <section
    className="services-two"
    style={{ backgroundColor: "#fff", paddingTop: 40 }}
  >
    <div className="container" >
      <div className="section-title text-center" style={{ marginBottom: 0 }}>
        <div className="section-title__tagline-box">
          <span className="section-title__tagline" style={{ color: "black" }}>
            Our Fleets
          </span>
        </div>
        <br />
        <img
          alt=""
          loading="lazy"
          width={97}
          height={13}
          decoding="async"
          data-nimg={1}
          style={{ color: "transparent" }}
          src="assets/images/svg/separator.eb50640e.svg"
        />
      </div>
      <div className="mt-4" >
        <div className="row" >
          <div className="col-lg-4 col-md-6" >
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  decoding="async"
                  data-nimg={1}

                  src="assets/images/fleet/png/c_1.png"
                style={{ color:'transparent'}}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }} className="alGallery">
                  Rolls Royce Phantom
                </div>
                <div className="al_brandName" style={{color:'goldenrod', fontWeight:'bold'}}>Rolls-Royce</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}}>3 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px',color:'white'}}>max. 2 Baggage</span>
                </div>
                <p style={{color:'goldenrod', fontWeight:'bold'}}>Luxury Class</p>
                <div className="" style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a 
                  className=" btnfleet" style={{ marginRight:'20px'}}
                    onClick={() => btnRollsRoycePhantom('rollsroycephantom')}
                  >
                    <span className="lte-btn-before" />
                   Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet " onClick={() => btnBookRollsRoycePhantom('Rolls Royce Phantom', '3 Seats','max. 2 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  decoding="async"
                  data-nimg={1}
                  src="assets/images/fleet/png/c_2.png"
                  style={{ color: "transparent", }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }} >Rolls Royce Ghost</div>
                <div style={{color:'goldenrod', fontWeight:'bold'}} className="al_brandName">Rolls-Royce</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white' }}>3 Seats</span>
                  <span className="vl" />
                  <span  style={{paddingLeft:'20px',color:'white' }}>max. 2 Baggage</span>
                </div>
                <p style={{color:'goldenrod', fontWeight:'bold'}}>Luxury Class</p>
                <div className="" style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                    className=" btnfleet" style={{ marginRight:'20px'}}
                    onClick={() => btnRollsRoycePhantom('rollsroyceghost',)}
                  >
                    <span className="lte-btn-before" />
                   Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet "  onClick={() => btnBookRollsRoycePhantom('Rolls Royce Ghost', '3 Seats','max. 2 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  decoding="async"
                  data-nimg={1}
                  src="assets/images/fleet/png/c_3.png"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>Rolls-Royce Cullinan</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}}className="al_brandName">Rolls-Royce</div>
                <div>
                  <span style={{paddingRight:'20px', color:'white'}} >3 Seats</span>
                  <span className="vl" />
                  <span  style={{paddingLeft:'20px',color:'white'}}>max. 2 Baggage</span>
                </div>
                <p style={{color:'goldenrod', fontWeight:'bold'}}>Luxury Class</p>
                <div className="" style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                    className=" btnfleet" style={{ marginRight:'20px'}}
                    onClick={() => btnRollsRoycePhantom('rollsroycecullinan',)}
                  >
                    <span className="lte-btn-before" />
                   Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet "  onClick={() => btnBookRollsRoycePhantom('Rolls-Royce Cullinan', '3 Seats','max. 2 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  decoding="async"
                  data-nimg={1}
                  src="assets/images/fleet/png/c_4.png"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>Bentley Flying Spur </div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">BENTLEY</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}} >3 Seats</span>
                  <span className="vl" />
                  <span  style={{paddingLeft:'20px',color:'white'}}>max. 2 Baggage</span>
                </div>
                <p style={{color:'goldenrod', fontWeight:'bold'}}>Luxury Class</p>
                <div style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                   className=" btnfleet" style={{ marginRight:'20px'}}
                    onClick={() => btnRollsRoycePhantom('bentleyflyingspur',)}
                  >
                    <span className="lte-btn-before" />
                   Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet "  onClick={() => btnBookRollsRoycePhantom('Bentley Flying Spur', '3 Seats','max. 2 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  decoding="async"
                  data-nimg={1}
                  src="assets/images/fleet/png/c_5.png"
                  style={{ color: "transparent",backgroundImage:'none' }}
                />
              </div>
              <div className="fleetTxt">
                <div  style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>Mercedes S Class </div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">MERCEDES</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}} >3 Seats</span>
                  <span className="vl" />
                  <span  style={{paddingLeft:'20px',color:'white'}} >max. 2 Baggage</span>
                </div>
                <p style={{color:'goldenrod', fontWeight:'bold'}}>Luxury Class</p>
                <div style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                    className=" btnfleet" style={{ marginRight:'20px'}}
                    onClick={() => btnRollsRoycePhantom('mercedessclass',)}
                  >
                    <span className="lte-btn-before" />
                   Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet "  onClick={() => btnBookRollsRoycePhantom('Mercedes S Class', '3 Seats','max. 2 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/png/c_6.png"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>BMW 7 series</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">BMW</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}} 
                  >3 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px',color:'white'}} 
                  >max. 2 Baggage</span>
                </div>
                <p style={{color:'goldenrod', fontWeight:'bold'}}
                >Luxury Class</p>
                <div style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                   className=" btnfleet" style={{ marginRight:'20px'}}
                    onClick={() => btnRollsRoycePhantom('bmw7series',)}
                  >
                    <span className="lte-btn-before" />
                   Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet "  onClick={() => btnBookRollsRoycePhantom('BMW 7 series', '3 Seats','max. 2 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        
          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/png/c_7.png"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>Audi A8 </div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">AUDI</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}} >3 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px',color:'white'}} >max. 2 Baggage</span>
                </div>
                <p style={{color:'goldenrod',fontWeight:'bold'}}>Luxury Class</p>
                <div style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                   className=" btnfleet" style={{ marginRight:'20px'}}
                    onClick={() => btnRollsRoycePhantom('audia8',)}
                  >
                    <span className="lte-btn-before" />
                   Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet"  onClick={() => btnBookRollsRoycePhantom('Audi A8', '3 Seats','max. 2 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          
          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/png/c_8.png"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>Cadillac Escalade</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">Cadillac Escalade</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}}>6 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px',color:'white'}}>max. 5 Baggage</span>
                </div>
                <p style={{color:'goldenrod',fontWeight:'bold'}}>Luxury Class</p>
                <div style={{paddingTop:'30px', paddingBottom:'10px'}} >
                  <a
                    className=" btnfleet" style={{ marginRight:'20px'}}
                    onClick={() => btnRollsRoycePhantom('cadillacescalade',)}
                  >
                    <span className="lte-btn-before" />
                   Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet"  onClick={() => btnBookRollsRoycePhantom('Cadillac Escalade', '6 Seats','max. 5 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/png/c_9.png"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>GMC Yukon</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">GMC</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}} >6 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px',color:'white'}} >max. 5 Baggage</span>
                </div>
                <p style={{color:'goldenrod',fontWeight:'bold'}}>Luxury Class</p>
                <div style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                   className="btnfleet" style={{ marginRight:'20px'}}
                    onClick={() => btnRollsRoycePhantom('gmcyukon',)}
                  >
                    <span className="lte-btn-before" />
                   Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet"  onClick={() => btnBookRollsRoycePhantom('GMC Yukon', '6 Seats','max. 5 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          
          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}
            >
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/png/c_10.png"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>Mercedes V class </div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">MERCEDES</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}}>7 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px',color:'white'}}>max. 5 Baggage</span>
                </div>
                <p style={{color:'goldenrod',fontWeight:'bold'}}>Luxury Class</p>
                <div style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                   className=" btnfleet" style={{ marginRight:'20px' }}
                   onClick={() => btnRollsRoycePhantom('mercedesvclass',)}
                  >
                    <span className="lte-btn-before" />
                   Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet"onClick={() => btnBookRollsRoycePhantom('Mercedes V class', '7 Seats','max. 5 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          
          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}
            >
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/png/c_11.png"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>Honda Odyssey</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">HONDA</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}} >4 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px',color:'white'}}>max. 5 Baggage</span>
                </div>
                <p style={{color:'goldenrod',fontWeight:'bold'}}>Luxury Class</p>
                <div style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                   className=" btnfleet" style={{ marginRight:'20px' }}
                   onClick={() => btnRollsRoycePhantom('hondaodyeesy',)}
                  >
                    <span className="lte-btn-before" />
                   Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet"onClick={() => btnBookRollsRoycePhantom('Honda Odyssey', '4 Seats','max. 5 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          
          <div className="col-lg-4 col-md-6">
          <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}
            >
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/png/c_12.png"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>Toyota Previa</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">TOYOTA</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}} >4 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px',color:'white'}} >max. 5 Baggage</span>
                </div>
                <p style={{color:'goldenrod',fontWeight:'bold'}}>Luxury Class</p>
                <div style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                   className=" btnfleet" style={{ marginRight:'20px' }}
                   onClick={() => btnRollsRoycePhantom('toyotaprevia',)}
                  >
                    <span className="lte-btn-before" />
                   Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet"onClick={() => btnBookRollsRoycePhantom('Toyota Previa', '4 Seats','max. 5 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/png/c_13.png"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>Toyota Granvia</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">TOYOTA</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}} >4 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px',color:'white'}} >max. 3 Baggage</span>
                </div>
                <p style={{color:'goldenrod',fontWeight:'bold'}}>Luxury Class</p>
                <div style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                   className=" btnfleet" style={{ marginRight:'20px' }}
                   onClick={() => btnRollsRoycePhantom('toyotagranvia',)}
                  >
                    <span className="lte-btn-before" />
                   Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet"onClick={() => btnBookRollsRoycePhantom('Toyota Granvia', '4 Seats','max. 3 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/png/c_14.png"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>Lexus ES 350</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">LEXUS</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}} >3 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px',color:'white'}} >max. 2 Baggage</span>
                </div>
                <p style={{color:'goldenrod',fontWeight:'bold'}}>Luxury Class</p>
                <div style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                   className=" btnfleet" style={{ marginRight:'20px' }}
                   onClick={() => btnRollsRoycePhantom('lexuses350',)}
                  >
                    <span className="lte-btn-before" />
                   Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet"onClick={() => btnBookRollsRoycePhantom('Lexus ES 350', '3 Seats','max. 2 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/png/c_15.png"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>Mercedes E Class</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">MERCEDES</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}} >3 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px',color:'white'}} >max. 2 Baggage</span>
                </div>
                <p style={{color:'goldenrod',fontWeight:'bold'}}>Luxury Class</p>
                <div style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                   className=" btnfleet" style={{ marginRight:'20px' }}
                   onClick={() => btnRollsRoycePhantom('mercedeseclass',)}
                  >
                    <span className="lte-btn-before" />
                   Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet"onClick={() => btnBookRollsRoycePhantom('Mercedes E Class', '3 Seats','max. 2 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/png/c_16.png"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>BMW 5 Series</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">BMW</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}} >3 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px',color:'white'}}>max. 2 Baggage</span>
                </div>
                <p style={{color:'goldenrod',fontWeight:'bold'}}>Luxury Class</p>
                <div style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                   className=" btnfleet" style={{ marginRight:'20px' }}
                   onClick={() => btnRollsRoycePhantom('bmw5series',)}
                  >
                    <span className="lte-btn-before" />
                   Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet"onClick={() => btnBookRollsRoycePhantom('BMW 5 Series', '3 Seats','max. 2 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets"  style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/png/c_17.png"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>Audi A6</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">AUDI</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}} >3 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px',color:'white'}}>max. 2 Baggage</span>
                </div>
                <p style={{color:'goldenrod',fontWeight:'bold'}}>Luxury Class</p>
                <div style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                   className=" btnfleet" style={{ marginRight:'20px' }}
                   onClick={() => btnRollsRoycePhantom('audia6',)}
                  >
                    <span className="lte-btn-before" />
                   Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet"onClick={() => btnBookRollsRoycePhantom('Audi A6', '3 Seats','max. 2 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/png/c_18.png"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>Mercedes Sprinter</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">MERCEDES</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}} >16 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px',color:'white'}} >max. 12 Baggage</span>
                </div>
                <p style={{color:'goldenrod',fontWeight:'bold'}}>Luxury Class</p>
                <div style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                   className=" btnfleet" style={{ marginRight:'20px' }}
                   onClick={() => btnRollsRoycePhantom('mercedessprinter',)}
                  >
                    <span className="lte-btn-before" />
                   Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet"onClick={() => btnBookRollsRoycePhantom('Mercedes Sprinter', '16 Seats','max. 12 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/png/c_19.png"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>Toyota Hiace</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">TOYOTA</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}} >11 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px',color:'white'}} >max. 7 Baggage</span>
                </div>
                <p style={{color:'goldenrod',fontWeight:'bold'}}>Luxury Class</p>
                <div style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                   className=" btnfleet" style={{ marginRight:'20px' }}
                   onClick={() => btnRollsRoycePhantom('toyotahiace',)}
                  >
                    <span className="lte-btn-before" />
                   Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet"onClick={() => btnBookRollsRoycePhantom('Toyota Hiace', '11 Seats','max. 7 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}
            >
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/png/c_20.png"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>Toyota Coaster </div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">TOYOTA</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}} >21 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px',color:'white'}} >max. 16 Baggage</span>
                </div>
                <p style={{color:'goldenrod',fontWeight:'bold'}}>Luxury Class</p>
                <div style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                   className=" btnfleet" style={{ marginRight:'20px' }}
                   onClick={() => btnRollsRoycePhantom('toyotacoaster',)}
                  >
                    <span className="lte-btn-before" />
                   Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet"onClick={() => btnBookRollsRoycePhantom('Toyota Coaster', '21 Seats','max. 16 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/png/c_21.png"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>35 Seater Luxury Bus</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">TOYOTA</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}} >35 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px',color:'white'}} >max. 30 Baggage</span>
                </div>
                <p style={{color:'goldenrod',fontWeight:'bold'}}>Luxury Class</p>
                <div style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                   className=" btnfleet" style={{ marginRight:'20px' }}
                   onClick={() => btnRollsRoycePhantom('seater35luxurybus',)}
                  >
                    <span className="lte-btn-before" />
                   Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet"onClick={() => btnBookRollsRoycePhantom('35 Seater Luxury Bus', '35 Seats','max. 30 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/png/c_22.png"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>50 Seater Luxury Bus</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">TOYOTA</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}} 
                  >50 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px',color:'white'}} 
                  >max. 45 Baggage</span>
                </div>
                <p style={{color:'goldenrod',fontWeight:'bold'}}
                >Luxury Class</p>
                 <div style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                   className=" btnfleet" style={{ marginRight:'20px' }}
                   onClick={() => btnRollsRoycePhantom('seater50luxurybus',)}
                  >
                    <span className="lte-btn-before" />
                   Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet"onClick={() => btnBookRollsRoycePhantom('50 Seater Luxury Bus', '50 Seats','max. 45 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/png/c_23.png"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>Tesla Model 3 </div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">TESLA</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}} 
                  >3 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px',color:'white'}} 
                  >max. 2 Baggage</span>
                </div>
                <p style={{color:'goldenrod',fontWeight:'bold'}}
                >Luxury Class</p>
                 <div style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                   className=" btnfleet" style={{ marginRight:'20px' }}
                   onClick={() => btnRollsRoycePhantom('teslamodel3',)}
                  >
                    <span className="lte-btn-before" />
                   Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet"onClick={() => btnBookRollsRoycePhantom('Tesla Model 3', '3 Seats','max. 2 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/png/c_24.png"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>Tesla Model Y</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">TESLA</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}} >3 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px',color:'white'}}>max. 3 Baggage</span>
                </div>
                <p style={{color:'goldenrod',fontWeight:'bold'}}>Luxury Class</p>
                <div style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                   className=" btnfleet" style={{ marginRight:'20px' }}
                   onClick={() => btnRollsRoycePhantom('teslamodely',)}
                  >
                    <span className="lte-btn-before" />
                   Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet"onClick={() => btnBookRollsRoycePhantom('Tesla Model Y', '3 Seats','max. 3 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/png/c_25.png"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>Strech Limousine </div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">MERCEDES</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}} >16 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px',color:'white'}}>max. 0 Baggage</span>
                </div>
                <p style={{color:'goldenrod',fontWeight:'bold'}}>Luxury Class</p>
                <div style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                   className=" btnfleet" style={{ marginRight:'20px' }}
                   onClick={() => btnRollsRoycePhantom('strechlimousine',)}
                  >
                    <span className="lte-btn-before" />
                   Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet"onClick={() => btnBookRollsRoycePhantom('Strech Limousine', '16 Seats','max. 0 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*Services Two End */}

    {/* /.page-wrapper */}
 <div className="mobile-nav__wrapper">
    <div className="mobile-nav__overlay mobile-nav__toggler" />
    {/* /.mobile-nav__overlay */}
    <div className="mobile-nav__content">
      <span className="mobile-nav__close mobile-nav__toggler">
        <i className="fa fa-times" />
      </span>
      <div className="logo-box">
        <a href="/" style={{ paddingLeft: "0PX" }}>
          <img
            src="assets/images/resources/logosmall.png"
            height="80px"
            style={{ marginLeft: "-40px" }}
            alt=""
          />
        </a>
      </div>
      {/* /.logo-box */}
      <div className="mobile-nav__container" />
      {/* /.mobile-nav__container */}
      <ul className="mobile-nav__contact list-unstyled">
        <li>
          <i className="fa fa-envelope" />
          <a href="mailto:needhelp@packageName__.com">
            booking@rizchauffeur.com
          </a>
        </li>
        <li>
          <i className="fa fa-phone-alt" />
          <a href="tel:666-888-0000">+971 563847412</a>
        </li>
      </ul>
      {/* /.mobile-nav__contact */}
      <div className="mobile-nav__top">
        <div className="mobile-nav__social">
          <a href="#" className="fab fa-twitter" />
          <a href="#" className="fab fa-facebook-square" />
          <a href="#" className="fab fa-pinterest-p" />
          <a href="#" className="fab fa-instagram" />
        </div>
        {/* /.mobile-nav__social */}
      </div>
      {/* /.mobile-nav__top */}
    </div>
    {/* /.mobile-nav__content */}
  </div>
  {/* /.mobile-nav__wrapper */}
  
  <div className="scroll-to-top">
    <svg className="scroll-top-inner" viewBox="-1 -1 102 102">
        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
    </svg>
</div>
        </div>
    )
};
export default OurFleet;