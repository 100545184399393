import { Link,useNavigate} from 'react-router-dom';
import React,{useEffect} from 'react';

function Index() {
  
  useEffect(() => {
    
    
  },[]);


  const navigate = useNavigate()

  const btnRollsRoycePhantom =(link)=>
  {
    navigate("/" + link);
  }
  const btnBookRollsRoycePhantom =(vehicle,seats,baggage)=>
    {
      navigate("/bookrizchauffeur", {state: {
        oneWayVehicle:vehicle,seats:seats,baggage:baggage
      }});
    }

    
  

    return (
      
        <div>
        
        <>

        <>
  <div className="stricky-header stricked-menu main-menu">
    <div className="sticky-header__content" />
    {/* /.sticky-header__content */}
  </div>
  {/* /.stricky-header */}
</>

  {/*Main Slider Start*/}
  <section className="">
  
  <div >

  <video playsinline autoPlay loop muted  width={"100%"} className='vi'>
    <source src={'assets/videos/rizchauffeur.mp4'} type='video/mp4' />
</video>
</div>
  </section>
  <section className="main-slider" style={{display:'none'}}>
    <div
      className="swiper-container thm-swiper__slider"
      data-swiper-options='{"slidesPerView": 1, "loop": true,
          "effect": "fade",
          "pagination": {
          "el": "#main-slider-pagination",
          "type": "bullets",
          "clickable": true
          },
          "navigation": {
          "nextEl": "#main-slider__swiper-button-next",
          "prevEl": "#main-slider__swiper-button-prev"
          },
          "autoplay": {
              "delay": 8000
          } 
      }'
    >
      <div className="swiper-wrapper">
        <div className="swiper-slide">
          <div
            className="main-slider__bg"
            style={{
              backgroundImage: "url(assets/images/backgrounds/slider-1-1.jpg)"
            }}
          />
          <div className="main-slider__shape-1 img-bounce">
            <img src="assets/images/shapes/main-slider-shape-1.png" alt="" />
          </div>
          <div className="main-slider__shape-2">
            <img src="assets/images/shapes/main-slider-shape-2.png" alt="" />
          </div>
          <div className="main-slider__shape-3">
            <img src="assets/images/shapes/main-slider-shape-3.png" alt="" />
          </div>
          <div className="main-slider__shape-4 float-bob-x">
            <img src="assets/images/shapes/main-slider-shape-4.png" alt="" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="main-slider__content">
                  <div className="main-slider__sub-title-box">
                    <p className="main-slider__sub-title">Innovation at Work</p>
                  </div>
                  <h2 className="main-slider__title">
                    Crafting Excellence <br /> Through Innovation
                  </h2>
                  <p className="main-slider__text">
                    We have been operating for over a decade, providing
                    top-notch services to our <br /> clients and building a
                    strong track record in the industry.We have been operating{" "}
                    <br /> for over a decade, providing top-notch services to
                    our clients .
                  </p>
                  <div className="main-slider__btn-and-video">
                    <div className="main-slider__btn-box">
                      <a href="/about" className="main-slider__btn thm-btn">
                        Read More
                        <span className="icon-arrow-left" />
                      </a>
                    </div>
                    <div className="main-slider__video-link">
                      <a
                        href="https://www.youtube.com/watch?v=Get7rqXYrbQ"
                        className="video-popup"
                      >
                        <div className="main-slider__video-icon">
                          <span className="icon-play" />
                          <i className="ripple" />
                        </div>
                      </a>
                      <h4 className="main-slider__video-text">
                        See How It Works
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="swiper-slide">
          <div
            className="main-slider__bg"
            style={{
              backgroundImage: "url(assets/images/backgrounds/slider-1-2.jpg)", display:'none'
            }}
          />
          <div className="main-slider__shape-1 img-bounce">
            <img src="assets/images/shapes/main-slider-shape-1.png" alt="" />
          </div>
          <div className="main-slider__shape-2">
            <img src="assets/images/shapes/main-slider-shape-2.png" alt="" />
          </div>
          <div className="main-slider__shape-3">
            <img src="assets/images/shapes/main-slider-shape-3.png" alt="" />
          </div>
          <div className="main-slider__shape-4 float-bob-x">
            <img src="assets/images/shapes/main-slider-shape-4.png" alt="" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="main-slider__content">
                  <div className="main-slider__sub-title-box">
                    <p className="main-slider__sub-title">Innovation at Work</p>
                  </div>
                  <h2 className="main-slider__title">
                    Crafting Excellence <br /> Through Innovation
                  </h2>
                  <p className="main-slider__text">
                    We have been operating for over a decade, providing
                    top-notch services to our <br /> clients and building a
                    strong track record in the industry.We have been operating{" "}
                    <br /> for over a decade, providing top-notch services to
                    our clients .
                  </p>
                  <div className="main-slider__btn-and-video">
                    <div className="main-slider__btn-box">
                      <a href="" className="main-slider__btn thm-btn">
                        Read More
                        <span className="icon-arrow-left" />
                      </a>
                    </div>
                    <div className="main-slider__video-link">
                      <a
                        href="https://www.youtube.com/watch?v=Get7rqXYrbQ"
                        className="video-popup"
                      >
                        <div className="main-slider__video-icon">
                          <span className="icon-play" />
                          <i className="ripple" />
                        </div>
                      </a>
                      <h4 className="main-slider__video-text">
                        See How It Works
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="swiper-slide">
          <div
            className="main-slider__bg"
            style={{
              backgroundImage: "url(assets/images/backgrounds/slider-1-3.jpg)"
            }}
          />
          <div className="main-slider__shape-1 img-bounce">
            <img src="assets/images/shapes/main-slider-shape-1.png" alt="" />
          </div>
          <div className="main-slider__shape-2">
            <img src="assets/images/shapes/main-slider-shape-2.png" alt="" />
          </div>
          <div className="main-slider__shape-3">
            <img src="assets/images/shapes/main-slider-shape-3.png" alt="" />
          </div>
          <div className="main-slider__shape-4 float-bob-x">
            <img src="assets/images/shapes/main-slider-shape-4.png" alt="" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="main-slider__content">
                  <div className="main-slider__sub-title-box">
                    <p className="main-slider__sub-title">Innovation at Work</p>
                  </div>
                  <h2 className="main-slider__title">
                    Crafting Excellence <br /> Through Innovation
                  </h2>
                  <p className="main-slider__text">
                    We have been operating for over a decade, providing
                    top-notch services to our <br /> clients and building a
                    strong track record in the industry.We have been operating{" "}
                    <br /> for over a decade, providing top-notch services to
                    our clients .
                  </p>
                  <div className="main-slider__btn-and-video">
                    <div className="main-slider__btn-box">
                      <a href="/about" className="main-slider__btn thm-btn">
                        Read More
                        <span className="icon-arrow-left" />
                      </a>
                    </div>
                    <div className="main-slider__video-link">
                      <a
                        href="https://www.youtube.com/watch?v=Get7rqXYrbQ"
                        className="video-popup"
                      >
                        <div className="main-slider__video-icon">
                          <span className="icon-play" />
                          <i className="ripple" />
                        </div>
                      </a>
                      <h4 className="main-slider__video-text">
                        See How It Works
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* If we need navigation buttons */}
      <div className="main-slider__nav">
        <div
          className="swiper-button-prev"
          id="main-slider__swiper-button-next"
        >
          <i className="icon-arrow-right" />
        </div>
        <div
          className="swiper-button-next"
          id="main-slider__swiper-button-prev"
        >
          <i className="icon-arrow-left" />
        </div>
      </div>
    </div>
  </section>
  {/*Main Slider End*/}
</>

<div className="blackBg" style={{ position: "relative" }}>
<div className="section-background">
<div className="background-wrapper">
    <div
      className="page-header__bg"
      style={{
        backgroundImage: "url(assets/images/project/rizfs_1.jpeg)"
      }}
    >
      </div>
      <div
        className="overlay-bg"
        style={{
          display: "block",
          backgroundImage:
            'url(assets/images/project/rizfs_2.jpeg)'
        }}
      ></div>
       <div className="row">
        <div className="col-lg-6 col-md-6 al_servicesWrap">
          <div className="al_container_inner">
            <div className="al_service_infoSec">
              <h2>Riz</h2>
              <h2>At a Glance</h2>
              <p>
              Riz is the top provider of luxury fleet services in the UAE and its surrounding areas. Riz's top-notch fleet management team guarantees customers a combination of luxury and security in every aspect. Having a decade of experience in coordinating and structuring sophisticated fleet management services builds people's trust in Riz. We provide skilled chauffeurs with extensive experience who demonstrate professionalism in delivering service, handling all situations and challenges during transportation. The enduring comfort of our long journey together will be supported by the trust you have placed in us. Riz offers a comprehensive solution for all fleet management and support needs around the clock. We cater to various rental services such as corporate transportation, intercity travel, airport and port transfers, limousine services, hourly rates, event transportation, ground transportation, city tours, desert safaris, staff transport, and top chauffeur services.{" "}
              </p>
              <a className="al_readmore " href="/about">
                <span className="lte-btn-before" />
                Read More
                <span className="lte-btn-after" />
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-6" />
      </div>
      </div>
      </div>
      </div>
   
     
    
  {/*Service One Start */}
  <section className="">
    <div className="container">
      <div className="row">
        {/*Services One Single Start*/}
        <div className="section-title__tagline-box" style={{ textAlign:'center', paddingTop:'20px' }}>
          <span className="section-title__tagline text-white" style={{ textAlign:'center' }}>
            Our Services
          </span>
        </div>
        <br />
        <img
          alt=""
          loading="lazy"
          width={97}
          height={13}
          decoding="async"
          data-nimg={1}
          style={{ color: "transparent", }}
          src="assets/images/svg/separator.eb50640e.svg"
        />
        <br></br>
        <div
          className="col-xl-4 col-lg-6 col-md-6 wow fadeInLeft"
          data-wow-delay="100ms"
        >
          <div className="service-one__single">
            <div className="service-one__icon">
              {/*<span class="icon-car"></span>*/}
              <img alt='' src='assets/images/service_icon/airport_1.jpg' style={{width:'170px', height:'120px', borderRadius:'10px'}}></img>
            </div>
            <h3 className="service-one__title">
              <Link to="/airporttransfer">Airport transfer</Link>
            </h3>
            <p className="service-one__text">
              When you are travelling to somewhere, it will be thrilling and
              exciting to enjoy your at its best and to reduce stress.{" "}
            </p>
            <div className="service-one__read-more">
              <Link to="/airporttransfer">
                Read More
                <span className="icon-arrow-left" />
              </Link>
            </div>
          </div>
        </div>
        {/*Services One Single End*/}
        {/*Services One Single Start*/}
        <div
          className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
          data-wow-delay="200ms"
        >
          <div className="service-one__single">
            <div className="service-one__icon">
            <img alt='' src='assets/images/service_icon/bus_3.jpg' style={{width:'170px', height:'120px', borderRadius:'10px'}}></img>
            </div>
            <h3 className="service-one__title">
              <Link to="/busrental">
                Bus | Minibus | Van rental
              </Link>
            </h3>
            <p className="service-one__text">
              Experience the epitome of luxury while exploring the vibrant city
              of Dubai with our exclusive luxury bus rental services.
            </p>
            <div className="service-one__read-more">
              <Link to="/busrental">
                Read More
                <span className="icon-arrow-left" />
              </Link>
            </div>
          </div>
        </div>
        {/*Services One Single End*/}
        {/*Services One Single Start*/}
        <div
          className="col-xl-4 col-lg-6 col-md-6 wow fadeInRight"
          data-wow-delay="300ms"
        >
          <div className="service-one__single">
            <div className="service-one__icon">
            <img alt='' src='assets/images/service_icon/ch_2.jpg' style={{width:'170px', height:'120px', borderRadius:'10px'}}></img>
            </div>
            <h3 className="service-one__title">
              <Link to="/chauffeurservices">Chauffeur Service</Link>
            </h3>
            <p className="service-one__text">
              The politeness and dedication along with the smart driving skills
              of the experienced chauffeurs ensure a safe and luxurious service.
            </p>
            <div className="service-one__read-more">
              <Link to="/chauffeurservices">
                Read More
                <span className="icon-arrow-left" />
              </Link>
            </div>
          </div>
        </div>
        {/*Services One Single End*/}
        {/*Services One Single Start*/}
        <div
          className="col-xl-4 col-lg-6 col-md-6 wow fadeInLeft"
          data-wow-delay="400ms"
        >
          <div className="service-one__single">
            <div className="service-one__icon">
            <img alt='' src='assets/images/service_icon/limo_2.png' style={{width:'170px', height:'120px', borderRadius:'10px'}}></img>
            </div>
            <h3 className="service-one__title">
              <Link to="/limousineservice">Limousine Service Dubai</Link>
            </h3>
            <p className="service-one__text">
              There you will have a comfortable journey and can join a
              discussion online or arrange it even inside the limousine.
            </p>
            <div className="service-one__read-more">
              <Link to="/limousineservice">
                Read More
                <span className="icon-arrow-left" />
              </Link>
            </div>
          </div>
        </div>
        {/*Services One Single End*/}
        {/*Services One Single Start*/}
        <div
          className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
          data-wow-delay="500ms"
        >
          <div className="service-one__single">
            <div className="service-one__icon">
            <img alt='' alt='' src='assets/images/service_icon/ch_3.jpg' style={{width:'170px', height:'120px', borderRadius:'10px'}}></img>
            </div>
            <h3 className="service-one__title">
              <Link to="/hourlyrateservice">Hourly Rate Service</Link>
            </h3>
            <p className="service-one__text">
              We provide a luxurious fleet of cars and vans that enable us to
              transport in the best way with professional chauffeurs.{" "}
            </p>
            <div className="service-one__read-more">
              <Link to="/hourlyrateservice">
                Read More
                <span className="icon-arrow-left" />
              </Link>
            </div>
          </div>
        </div>
        {/*Services One Single End*/}
        {/*Services One Single Start*/}
        <div
          className="col-xl-4 col-lg-6 col-md-6 wow fadeInRight"
          data-wow-delay="600ms"
        >
          <div className="service-one__single">
            <div className="service-one__icon">
            <img alt='' src='assets/images/service_icon/gt_2.jpg' style={{width:'170px', height:'120px', borderRadius:'10px'}}></img>
            </div>
            <h3 className="service-one__title">
              <Link to="/groundtransportation">Ground Transportation</Link>
            </h3>
            <p className="service-one__text">
              Customer satisfaction is crucial for amohlodi business as it leads
              to customer loyalty loves positive word-of-mouth, repeat.{" "}
            </p>
            <div className="service-one__read-more">
              <Link to="/groundtransportation">
                Read More
                <span className="icon-arrow-left" />
              </Link>
            </div>
          </div>
        </div>
        {/*Services One Single End*/}
        {/*Services One Single Start*/}
        <div
          className="col-xl-4 col-lg-6 col-md-6 wow fadeInRight"
          data-wow-delay="600ms"
        >
          <div className="service-one__single">
            <div className="service-one__icon">
            <img alt='' src='assets/images/service_icon/et_2.jpeg' style={{width:'170px', height:'120px', borderRadius:'10px'}}></img>
            </div>
            <h3 className="service-one__title">
              <Link to="/groundtransportation">Event Transportation</Link>
            </h3>
            <p className="service-one__text">
            When it comes to event transportation, a proper logistics and transportation management 
            system is essential for the success of a particular event,
             {/*something that many may overlook.{" "}*/}
            </p>
            <div className="service-one__read-more">
              <Link to="/groundtransportation">
                Read More
                <span className="icon-arrow-left" />
              </Link>
            </div>
          </div>
        </div>
        {/*Services One Single End*/}
        {/*Services One Single Start*/}
        <div
          className="col-xl-4 col-lg-6 col-md-6 wow fadeInRight"
          data-wow-delay="600ms"
        >
          <div className="service-one__single">
            <div className="service-one__icon">
            <img alt='' src='assets/images/service_icon/ct_2.jpg' style={{width:'170px', height:'120px', borderRadius:'10px'}}></img>
            </div>
            <h3 className="service-one__title">
              <Link to="/groundtransportation">Corporate Transportation</Link>
            </h3>
            <p className="service-one__text">
            There is no need to search anymore for a dependable high-end corporate transportation 
            service to help with your everyday requirements. 
             {/*Get comfortable while our experienced chauffeurs drive you to where you need to go.*/}
            </p>
            <div className="service-one__read-more">
              <Link to="/groundtransportation">
                Read More
                <span className="icon-arrow-left" />
              </Link>
            </div>
          </div>
        </div>
        {/*Services One Single End*/}
        {/*Services One Single Start*/}
        <div
          className="col-xl-4 col-lg-6 col-md-6 wow fadeInRight"
          data-wow-delay="600ms"
        >
          <div className="service-one__single">
            <div className="service-one__icon">
            <img alt='' src='assets/images/service_icon/ct_4.jpg' style={{width:'170px', height:'120px', borderRadius:'10px'}}></img>
            </div>
            <h3 className="service-one__title">
              <Link to="/groundtransportation">City Tour</Link>
            </h3>
            <p className="service-one__text">
            Riz chauffeurs distinguish themselves by going above and beyond to prioritize safety and security,
             paying close attention to details during family. 
             {/*road trips, errands, or nights out in the city.*/}
            </p>
            <div className="service-one__read-more">
              <Link to="/groundtransportation">
                Read More
                <span className="icon-arrow-left" />
              </Link>
            </div>
          </div>
        </div>
        {/*Services One Single End*/}

         {/*Services One Single Start*/}
         <div
          className="col-xl-4 col-lg-6 col-md-6 wow fadeInRight"
          data-wow-delay="600ms"
        >
          <div className="service-one__single">
            <div className="service-one__icon">
            <img alt='' src='assets/images/service_icon/pt_3.jpeg' style={{width:'170px', height:'120px', borderRadius:'10px'}}></img>
            </div>
            <h3 className="service-one__title">
              <Link to="/groundtransportation">Port Transfer</Link>
            </h3>
            <p className="service-one__text">
            Riz drivers guarantee stress-free and fashionable transportation in and out of the city, 
            by effectively catering to your safety, comfort, security, 
            
             {/*reliability, and credibility needs. 
            We are prepared to offer you a enjoyable experience, even while traveling, with the highest level of comfort.*/}
            </p>
            <div className="service-one__read-more">
              <Link to="/groundtransportation">
                Read More
                <span className="icon-arrow-left" />
              </Link>
            </div>
          </div>
        </div>
        {/*Services One Single End*/}
        {/*Services One Single Start*/}
        <div
          className="col-xl-4 col-lg-6 col-md-6 wow fadeInRight"
          data-wow-delay="600ms"
        >
          <div className="service-one__single">
            <div className="service-one__icon">
            <img alt='' src='assets/images/service_icon/it_1.jpg' style={{width:'170px', height:'120px', borderRadius:'10px'}}></img>
            </div>
            <h3 className="service-one__title">
              <Link to="/groundtransportation">Intercity Transfer</Link>
            </h3>
            <p className="service-one__text">
            Riz Chauffeur Service is the top chauffeur service provider in the UAE. Our team operates around the clock, 
            offering booking confirmation.
             {/*for one-way, full-day, and half-day services from anywhere in the UAE.
             During your travels, we aim to ensure privacy and relaxation for you.*/}
            </p>
            <div className="service-one__read-more">
              <Link to="/groundtransportation">
                Read More
                <span className="icon-arrow-left" />
              </Link>
            </div>
          </div>
        </div>
        {/*Services One Single End*/}
        {/*Services One Single Start*/}
        <div
          className="col-xl-4 col-lg-6 col-md-6 wow fadeInRight"
          data-wow-delay="600ms"
        >
          <div className="service-one__single">
            <div className="service-one__icon">
            <img alt='' src='assets/images/service_icon/ds_1.jpg' style={{width:'170px', height:'120px', borderRadius:'10px'}}></img>
            </div>
            <h3 className="service-one__title">
              <Link to="/groundtransportation">Desert Safaari</Link>
            </h3>
            <p className="service-one__text">
            During the afternoon, we will tour the Dubai desert with multiple breaks for taking photos. 
            You can expect a gorgeous sunset.
             {/*following a thrilling dune excursion*/}
            </p>
            <div className="service-one__read-more">
              <Link to="/groundtransportation">
                Read More
                <span className="icon-arrow-left" />
              </Link>
            </div>
          </div>
        </div>
        {/*Services One Single End*/}

          {/*Services One Single Start*/}
          <div
          className="col-xl-4 col-lg-6 col-md-6 wow fadeInRight"
          data-wow-delay="600ms"
        >
          <div className="service-one__single">
            <div className="service-one__icon">
            <img alt='' src='assets/images/service_icon/st_1.jpg' style={{width:'170px', height:'120px', borderRadius:'10px'}}></img>
            </div>
            <h3 className="service-one__title">
              <Link to="/groundtransportation">Staff Transportation</Link>
            </h3>
            <p className="service-one__text">
            We provide transportation services for employees in Dubai. 
            Our high-end bus for transporting employees is consistently clean, 
            tidy, and budget.
             {/*-friendly. You can rely on our staff transportation services in Dubai.
             We provide a range of buses and vans, from a 14 seater HiAce to a 50 seater luxury bus.
*/}
            </p>
            <div className="service-one__read-more">
              <Link to="/groundtransportation">
                Read More
                <span className="icon-arrow-left" />
              </Link>
            </div>
          </div>
        </div>
        {/*Services One Single End*/}
        {/*Services One Single Start*/}
        <div
          className="col-xl-4 col-lg-6 col-md-6 wow fadeInRight"
          data-wow-delay="600ms"
        >
          <div className="service-one__single">
            <div className="service-one__icon">
            <img alt='' src='assets/images/service_icon/lr_1.jpg' style={{width:'170px', height:'120px', borderRadius:'10px'}}></img>
            </div>
            <h3 className="service-one__title">
              <Link to="/groundtransportation">Limo Ride Dubai</Link>
            </h3>
            <p className="service-one__text">
            Limousines are vehicles that are entirely distinctive. There is always a link between VIPs and high status. 
            Yet, not all individuals have the financial.
             {/*means to purchase such a car. 
            Party limousine hire prices may not be as costly as perceived, making it a luxury now accessible to all.*/}
            </p>
            <div className="service-one__read-more">
              <Link to="/groundtransportation">
                Read More
                <span className="icon-arrow-left" />
              </Link>
            </div>
          </div>
        </div>
        {/*Services One Single End*/}
      </div>
    </div>
  </section>
  {/*Service One End */}
  {/*About One Start */}
  <section className="about-one">
    <div className="about-one__shape-2 float-bob">
      <img src="assets/images/shapes/about-one-shape-2.png" alt="" />
    </div>
    <div className="container">
      <div className="row">
        <div className="col-xl-6">
          <div
            className="about-one__left wow slideInLeft"
            data-wow-delay="100ms"
            data-wow-duration="2500ms"
          >
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="about-one__img-1">
                  <img src="assets/images/resources/about-1-1.jpg" alt="" />
                  <div className="about-one__shape-1 float-bob-y">
                    <img
                      src="assets/images/shapes/about-one-shape-1.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="about-one__img-2">
                  <img src="assets/images/resources/about-1-2.jpg" alt="" />
                  {/*<div class="about-one__award-box">
                                      <div class="about-one__award-icon">
                                          <span class="icon-award"></span>
                                      </div>
                                      <div class="about-one__count count-box">
                                          <h3 class="count-text" data-stop="20" data-speed="1500">00</h3>
                                          <span>+</span>
                                      </div>
                                      <p class="about-one__count-text">Winning award</p>
                                  </div>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="about-one__right">
            <div className="section-title text-left">
              <div className="section-title__tagline-box">
                <span className="section-title__tagline">ABOUT US</span>
              </div>
              <h2 className="section-title__title">
                Reliable best luxury fleet Professionals at Your Service
              </h2>
            </div>
            <p className="about-one__text">
            The most famous and elegant travel destination in the world is popular for its novelty and lushness that it provides. 
            To explore the real amusement of Dubai the need for a professional chauffeur service is a vital thing, that’s what *RIZ* for you. As our tagline indicates, one can realize that RIZ is the Epitome for anyone’s imagination on luxury.
            </p>
            <p className="about-one__text pt-2">
            RIZ Chauffeur was found on a vision of offering the best and unbeaten service by utilizing all available modern technologies and by following industry standards in every bit and keeping on updating in every moment.
            </p>
            <p className="about-one__text pt-2">
              We offer a diverse fleet of luxury vehicles to meet your
              transportation needs. Whether you need a taxicab, minivan, SUV, or
              VIP limo, we have it all. Our vehicles are regularly maintained to
              ensure a safe and comfortable ride for our clients.
            </p>
            
            <div className="about-one__btn-box mt-4">
              <a href="/about" className="about-one__btn thm-btn">
                Read More
                <span className="icon-arrow-left" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*About One End */}
  {/*Services Two Start */}
  <section
    className="services-two"
    style={{ backgroundColor: "#fff", paddingTop: 40 }}
  >
    <div className="container" >
      <div className="section-title text-center" style={{ marginBottom: 0 }}>
        <div className="section-title__tagline-box">
          <span className="section-title__tagline" style={{ color: "black" }}>
            Our Fleets
          </span>
        </div>
        <br />
        <img
          alt=""
          loading="lazy"
          width={97}
          height={13}
          decoding="async"
          data-nimg={1}
          style={{ color: "transparent" }}
          src="assets/images/svg/separator.eb50640e.svg"
        />
      </div>
      <div className="mt-4" >
        <div className="row" >
          <div className="col-lg-4 col-md-6" >
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  decoding="async"
                  data-nimg={1}

                  src="assets/images/fleet/png/c_1.png"
                style={{ color:'transparent'}}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }} className="alGallery">
                  Rolls Royce Phantom
                </div>
                <div className="al_brandName" style={{color:'goldenrod', fontWeight:'bold'}}>Rolls-Royce</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}}>3 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px',color:'white'}}>max. 2 Baggage</span>
                </div>
                <p style={{color:'goldenrod', fontWeight:'bold'}}>Luxury Class</p>
                <div className="" style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a 
                  className=" btnfleet" style={{ marginRight:'20px'}}
                    onClick={() => btnRollsRoycePhantom('rollsroycephantom')}
                  >
                    <span className="lte-btn-before" />
                    Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet " onClick={() => btnBookRollsRoycePhantom('Rolls Royce Phantom', '3 Seats','max. 2 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  decoding="async"
                  data-nimg={1}
                  src="assets/images/fleet/png/c_2.png"
                  style={{ color: "transparent", }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }} >Rolls Royce Ghost</div>
                <div style={{color:'goldenrod', fontWeight:'bold'}} className="al_brandName">Rolls-Royce</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white' }}>3 Seats</span>
                  <span className="vl" />
                  <span  style={{paddingLeft:'20px',color:'white' }}>max. 2 Baggage</span>
                </div>
                <p style={{color:'goldenrod', fontWeight:'bold'}}>Luxury Class</p>
                <div className="" style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                    className=" btnfleet" style={{ marginRight:'20px'}}
                    onClick={() => btnRollsRoycePhantom('rollsroyceghost',)}
                  >
                    <span className="lte-btn-before" />
                    Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet "  onClick={() => btnBookRollsRoycePhantom('Rolls Royce Ghost', '3 Seats','max. 2 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  decoding="async"
                  data-nimg={1}
                  src="assets/images/fleet/png/c_3.png"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>Rolls-Royce Cullinan</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}}className="al_brandName">Rolls-Royce</div>
                <div>
                  <span style={{paddingRight:'20px', color:'white'}} >3 Seats</span>
                  <span className="vl" />
                  <span  style={{paddingLeft:'20px',color:'white'}}>max. 2 Baggage</span>
                </div>
                <p style={{color:'goldenrod', fontWeight:'bold'}}>Luxury Class</p>
                <div className="" style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                    className=" btnfleet" style={{ marginRight:'20px'}}
                    onClick={() => btnRollsRoycePhantom('rollsroycecullinan',)}
                  >
                    <span className="lte-btn-before" />
                    Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet "  onClick={() => btnBookRollsRoycePhantom('Rolls-Royce Cullinan', '3 Seats','max. 2 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  decoding="async"
                  data-nimg={1}
                  src="assets/images/fleet/png/c_4.png"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>Bentley Flying Spur </div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">BENTLEY</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}} >3 Seats</span>
                  <span className="vl" />
                  <span  style={{paddingLeft:'20px',color:'white'}}>max. 2 Baggage</span>
                </div>
                <p style={{color:'goldenrod', fontWeight:'bold'}}>Luxury Class</p>
                <div style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                   className=" btnfleet" style={{ marginRight:'20px'}}
                    onClick={() => btnRollsRoycePhantom('bentleyflyingspur',)}
                  >
                    <span className="lte-btn-before" />
                    Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet "  onClick={() => btnBookRollsRoycePhantom('Bentley Flying Spur', '3 Seats','max. 2 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  decoding="async"
                  data-nimg={1}
                  src="assets/images/fleet/png/c_5.png"
                  style={{ color: "transparent",backgroundImage:'none' }}
                />
              </div>
              <div className="fleetTxt">
                <div  style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>Mercedes S Class </div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">MERCEDES</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}} >3 Seats</span>
                  <span className="vl" />
                  <span  style={{paddingLeft:'20px',color:'white'}} >max. 2 Baggage</span>
                </div>
                <p style={{color:'goldenrod', fontWeight:'bold'}}>Luxury Class</p>
                <div style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                    className=" btnfleet" style={{ marginRight:'20px'}}
                    onClick={() => btnRollsRoycePhantom('mercedessclass',)}
                  >
                    <span className="lte-btn-before" />
                    Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet "  onClick={() => btnBookRollsRoycePhantom('Mercedes S Class', '3 Seats','max. 2 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/png/c_6.png"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>BMW 7 series</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">BMW</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}} 
                  >3 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px',color:'white'}} 
                  >max. 2 Baggage</span>
                </div>
                <p style={{color:'goldenrod', fontWeight:'bold'}}
                >Luxury Class</p>
                <div style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                   className=" btnfleet" style={{ marginRight:'20px'}}
                    onClick={() => btnRollsRoycePhantom('bmw7series',)}
                  >
                    <span className="lte-btn-before" />
                    Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet "  onClick={() => btnBookRollsRoycePhantom('BMW 7 series', '3 Seats','max. 2 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        
          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/png/c_7.png"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>Audi A8 </div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">AUDI</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}} >3 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px',color:'white'}} >max. 2 Baggage</span>
                </div>
                <p style={{color:'goldenrod',fontWeight:'bold'}}>Luxury Class</p>
                <div style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                   className=" btnfleet" style={{ marginRight:'20px'}}
                    onClick={() => btnRollsRoycePhantom('audia8',)}
                  >
                    <span className="lte-btn-before" />
                    Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet"  onClick={() => btnBookRollsRoycePhantom('Audi A8', '3 Seats','max. 2 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          
          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/png/c_8.png"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>Cadillac Escalade</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">Cadillac Escalade</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}}>6 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px',color:'white'}}>max. 5 Baggage</span>
                </div>
                <p style={{color:'goldenrod',fontWeight:'bold'}}>Luxury Class</p>
                <div style={{paddingTop:'30px', paddingBottom:'10px'}} >
                  <a
                    className=" btnfleet" style={{ marginRight:'20px'}}
                    onClick={() => btnRollsRoycePhantom('cadillacescalade',)}
                  >
                    <span className="lte-btn-before" />
                    Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet"  onClick={() => btnBookRollsRoycePhantom('Cadillac Escalade', '6 Seats','max. 5 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/png/c_9.png"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>GMC Yukon</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">GMC</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}} >6 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px',color:'white'}} >max. 5 Baggage</span>
                </div>
                <p style={{color:'goldenrod',fontWeight:'bold'}}>Luxury Class</p>
                <div style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                   className="btnfleet" style={{ marginRight:'20px'}}
                    onClick={() => btnRollsRoycePhantom('gmcyukon',)}
                  >
                    <span className="lte-btn-before" />
                    Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet"  onClick={() => btnBookRollsRoycePhantom('GMC Yukon', '6 Seats','max. 5 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          
          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}
            >
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/png/c_10.png"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>Mercedes V class </div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">MERCEDES</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}}>7 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px',color:'white'}}>max. 5 Baggage</span>
                </div>
                <p style={{color:'goldenrod',fontWeight:'bold'}}>Luxury Class</p>
                <div style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                   className=" btnfleet" style={{ marginRight:'20px' }}
                   onClick={() => btnRollsRoycePhantom('mercedesvclass',)}
                  >
                    <span className="lte-btn-before" />
                   Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet"onClick={() => btnBookRollsRoycePhantom('Mercedes V class', '7 Seats','max. 5 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          
          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}
            >
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/png/c_11.png"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>Honda Odyssey</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">HONDA</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}} >4 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px',color:'white'}}>max. 5 Baggage</span>
                </div>
                <p style={{color:'goldenrod',fontWeight:'bold'}}>Luxury Class</p>
                <div style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                   className=" btnfleet" style={{ marginRight:'20px' }}
                   onClick={() => btnRollsRoycePhantom('hondaodyeesy',)}
                  >
                    <span className="lte-btn-before" />
                   Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet"onClick={() => btnBookRollsRoycePhantom('Honda Odyssey', '4 Seats','max. 5 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          
          <div className="col-lg-4 col-md-6">
          <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}
            >
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/png/c_12.png"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>Toyota Previa</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">TOYOTA</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}} >4 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px',color:'white'}} >max. 5 Baggage</span>
                </div>
                <p style={{color:'goldenrod',fontWeight:'bold'}}>Luxury Class</p>
                <div style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                   className=" btnfleet" style={{ marginRight:'20px' }}
                   onClick={() => btnRollsRoycePhantom('toyotaprevia',)}
                  >
                    <span className="lte-btn-before" />
                   Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet"onClick={() => btnBookRollsRoycePhantom('Toyota Previa', '4 Seats','max. 5 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/png/c_13.png"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>Toyota Granvia</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">TOYOTA</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}} >4 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px',color:'white'}} >max. 3 Baggage</span>
                </div>
                <p style={{color:'goldenrod',fontWeight:'bold'}}>Luxury Class</p>
                <div style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                   className=" btnfleet" style={{ marginRight:'20px' }}
                   onClick={() => btnRollsRoycePhantom('toyotagranvia',)}
                  >
                    <span className="lte-btn-before" />
                   Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet"onClick={() => btnBookRollsRoycePhantom('Toyota Granvia', '4 Seats','max. 3 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/png/c_14.png"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>Lexus ES 350</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">LEXUS</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}} >3 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px',color:'white'}} >max. 2 Baggage</span>
                </div>
                <p style={{color:'goldenrod',fontWeight:'bold'}}>Luxury Class</p>
                <div style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                   className=" btnfleet" style={{ marginRight:'20px' }}
                   onClick={() => btnRollsRoycePhantom('lexuses350',)}
                  >
                    <span className="lte-btn-before" />
                   Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet"onClick={() => btnBookRollsRoycePhantom('Lexus ES 350', '3 Seats','max. 2 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/png/c_15.png"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>Mercedes E Class</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">MERCEDES</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}} >3 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px',color:'white'}} >max. 2 Baggage</span>
                </div>
                <p style={{color:'goldenrod',fontWeight:'bold'}}>Luxury Class</p>
                <div style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                   className=" btnfleet" style={{ marginRight:'20px' }}
                   onClick={() => btnRollsRoycePhantom('mercedeseclass',)}
                  >
                    <span className="lte-btn-before" />
                   Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet"onClick={() => btnBookRollsRoycePhantom('Mercedes E Class', '3 Seats','max. 2 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/png/c_16.png"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>BMW 5 Series</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">BMW</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}} >3 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px',color:'white'}}>max. 2 Baggage</span>
                </div>
                <p style={{color:'goldenrod',fontWeight:'bold'}}>Luxury Class</p>
                <div style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                   className=" btnfleet" style={{ marginRight:'20px' }}
                   onClick={() => btnRollsRoycePhantom('bmw5series',)}
                  >
                    <span className="lte-btn-before" />
                   Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet"onClick={() => btnBookRollsRoycePhantom('BMW 5 Series', '3 Seats','max. 2 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets"  style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/png/c_17.png"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>Audi A6</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">AUDI</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}} >3 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px',color:'white'}}>max. 2 Baggage</span>
                </div>
                <p style={{color:'goldenrod',fontWeight:'bold'}}>Luxury Class</p>
                <div style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                   className=" btnfleet" style={{ marginRight:'20px' }}
                   onClick={() => btnRollsRoycePhantom('audia6',)}
                  >
                    <span className="lte-btn-before" />
                   Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet"onClick={() => btnBookRollsRoycePhantom('Audi A6', '3 Seats','max. 2 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/png/c_18.png"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>Mercedes Sprinter</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">MERCEDES</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}} >16 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px',color:'white'}} >max. 12 Baggage</span>
                </div>
                <p style={{color:'goldenrod',fontWeight:'bold'}}>Luxury Class</p>
                <div style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                   className=" btnfleet" style={{ marginRight:'20px' }}
                   onClick={() => btnRollsRoycePhantom('mercedessprinter',)}
                  >
                    <span className="lte-btn-before" />
                   Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet"onClick={() => btnBookRollsRoycePhantom('Mercedes Sprinter', '16 Seats','max. 12 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/png/c_19.png"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>Toyota Hiace</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">TOYOTA</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}} >11 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px',color:'white'}} >max. 7 Baggage</span>
                </div>
                <p style={{color:'goldenrod',fontWeight:'bold'}}>Luxury Class</p>
                <div style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                   className=" btnfleet" style={{ marginRight:'20px' }}
                   onClick={() => btnRollsRoycePhantom('toyotahiace',)}
                  >
                    <span className="lte-btn-before" />
                   Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet"onClick={() => btnBookRollsRoycePhantom('Toyota Hiace', '11 Seats','max. 7 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}
            >
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/png/c_20.png"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>Toyota Coaster </div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">TOYOTA</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}} >21 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px',color:'white'}} >max. 16 Baggage</span>
                </div>
                <p style={{color:'goldenrod',fontWeight:'bold'}}>Luxury Class</p>
                <div style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                   className=" btnfleet" style={{ marginRight:'20px' }}
                   onClick={() => btnRollsRoycePhantom('toyotacoaster',)}
                  >
                    <span className="lte-btn-before" />
                   Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet"onClick={() => btnBookRollsRoycePhantom('Toyota Coaster', '21 Seats','max. 16 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/png/c_21.png"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>35 Seater Luxury Bus</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">TOYOTA</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}} >35 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px',color:'white'}} >max. 30 Baggage</span>
                </div>
                <p style={{color:'goldenrod',fontWeight:'bold'}}>Luxury Class</p>
                <div style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                   className=" btnfleet" style={{ marginRight:'20px' }}
                   onClick={() => btnRollsRoycePhantom('seater35luxurybus',)}
                  >
                    <span className="lte-btn-before" />
                   Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet"onClick={() => btnBookRollsRoycePhantom('35 Seater Luxury Bus', '35 Seats','max. 30 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/png/c_22.png"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>50 Seater Luxury Bus</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">TOYOTA</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}} 
                  >50 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px',color:'white'}} 
                  >max. 45 Baggage</span>
                </div>
                <p style={{color:'goldenrod',fontWeight:'bold'}}
                >Luxury Class</p>
                <div style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                   className=" btnfleet" style={{ marginRight:'20px' }}
                   onClick={() => btnRollsRoycePhantom('seater50luxurybus',)}
                  >
                    <span className="lte-btn-before" />
                   Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet"onClick={() => btnBookRollsRoycePhantom('50 Seater Luxury Bus', '50 Seats','max. 45 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/png/c_23.png"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>Tesla Model 3 </div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">TESLA</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}} 
                  >3 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px',color:'white'}} 
                  >max. 2 Baggage</span>
                </div>
                <p style={{color:'goldenrod',fontWeight:'bold'}}
                >Luxury Class</p>
                <div style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                   className=" btnfleet" style={{ marginRight:'20px' }}
                   onClick={() => btnRollsRoycePhantom('teslamodel3',)}
                  >
                    <span className="lte-btn-before" />
                   Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet"onClick={() => btnBookRollsRoycePhantom('Tesla Model 3', '3 Seats','max. 2 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/png/c_24.png"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>Tesla Model Y</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">TESLA</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}} >3 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px',color:'white'}}>max. 3 Baggage</span>
                </div>
                <p style={{color:'goldenrod',fontWeight:'bold'}}>Luxury Class</p>
                <div style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                   className=" btnfleet" style={{ marginRight:'20px' }}
                   onClick={() => btnRollsRoycePhantom('teslamodely',)}
                  >
                    <span className="lte-btn-before" />
                   Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet"onClick={() => btnBookRollsRoycePhantom('Tesla Model Y', '3 Seats','max. 3 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'4px solid goldenrod', borderRadius:'20px',
              backgroundImage:'url(assets/images/backgrounds/lbg_13.jpg)',objectFit:'cover', 
              backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/png/c_25.png"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'#ffdb57',fontWeight:'bold', textTransform:'uppercase' }}>Strech Limousine </div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">MERCEDES</div>
                <div>
                  <span style={{paddingRight:'20px',color:'white'}} >16 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px',color:'white'}}>max. 0 Baggage</span>
                </div>
                <p style={{color:'goldenrod',fontWeight:'bold'}}>Luxury Class</p>
                <div style={{paddingTop:'30px', paddingBottom:'10px'}}>
                  <a
                   className=" btnfleet" style={{ marginRight:'20px' }}
                   onClick={() => btnRollsRoycePhantom('strechlimousine',)}
                  >
                    <span className="lte-btn-before" />
                   Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="btnfleet"onClick={() => btnBookRollsRoycePhantom('Strech Limousine', '16 Seats','max. 0 Baggage')}>
                    <span className="lte-btn-before" />
                   Book
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*Services Two End */}
  {/*Contact One Start */}
  <section className="contact-one" style={{display:'none'}}>
    <div className="container">
      <div className="row">
        <div className="col-xl-5">
          <div className="contact-one__left">
            <h3 className="contact-one__title">
              Transform Your RIZ with <br />
              Our Your Expertise
            </h3>
            <div
              className="contact-one__img wow slideInLeft"
              data-wow-delay="100ms"
              data-wow-duration="2500ms"
            >
              <img src="assets/images/resources/contact-one-img-1.jpg" alt="" />
              <div className="contact-one__video-link">
                <a
                  href="https://www.youtube.com/watch?v=Get7rqXYrbQ"
                  className="video-popup"
                >
                  <div className="contact-one__video-icon">
                    <span className="icon-play" />
                    <i className="ripple" />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-7">
          <div
            className="contact-one__right wow slideInRight"
            data-wow-delay="100ms"
            data-wow-duration="2500ms"
          >
            <h3 className="contact-one__right-title">Request A Quote </h3>
            <p className="contact-one__right-text">
              Dictum ultrices porttitor amet nec sollicitudin mi molestie
              adipiscing netus. Lorem at ac ut morbi ullamcorper molestie lacus.
              Euismod nulla viverra condimentum
            </p>
            <form
              action="assets/inc/sendemail.php"
              className="contact-one__form contact-form-validated"
              noValidate="novalidate"
            >
              <div className="row">
                <div className="col-xl-6 col-lg-6">
                  <p className="contact-one__input-title">Your Email</p>
                  <div className="contact-one__input-box">
                    <div className="contact-one__input-icon">
                      <span className="icon-paper-plan" />
                    </div>
                    <input type="email" placeholder="Your Email" name="email" />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6">
                  <p className="contact-one__input-title">Your Phone</p>
                  <div className="contact-one__input-box">
                    <div className="contact-one__input-icon">
                      <span className="icon-call" />
                    </div>
                    <input type="text" placeholder="Your Phone" name="email" />
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12">
                  <p className="contact-one__input-title">Your Address</p>
                  <div className="contact-one__input-box">
                    <div className="contact-one__input-icon">
                      <span className="icon-location" />
                    </div>
                    <input
                      type="text"
                      placeholder="Your Address"
                      name="email"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12 col-lg-12">
                  <p className="contact-one__input-title">Message</p>
                  <div className="contact-one__input-box text-message-box">
                    <div className="contact-one__input-icon">
                      <span className="icon-envolop" />
                    </div>
                    <textarea
                      name="message"
                      placeholder="Write Message.."
                      defaultValue={""}
                    />
                  </div>
                  <div className="contact-one__btn-box">
                    <button type="submit" className="thm-btn contact-one__btn">
                      Send Message
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <div className="result" />
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*Contact One End */}

  <section >
  <div className="al_benefits" style={{backgroundColor:'#e9ecef'}}>
  <div className="container">
    <div className="al_our_services">
      <div className="mraTitle">
        <div className="contentSectn" style={{ margin: 0 }}>
          <h4> Specialities</h4>
          <img
            alt=""
            loading="lazy"
            width={97}
            height={13}
            decoding="async"
            data-nimg={1}
            src="/_next/static/media/separator.eb50640e.svg"
            style={{ color: "transparent" }}
          />
        </div>
        <p style={{ color: "rgb(0, 0, 0)", opacity: 1 }}>
          No Compromise on Safety and Comfort
        </p>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-3 col-md-6 al_benefits_outer">
        <div className="al_benefits_list">
          <img
            alt=""
            loading="lazy"
            width={1080}
            height={1080}
            decoding="async"
            data-nimg={1}
            srcSet="assets/images/project/sp_2.jpg"
            src="assets/images/project/sp_2.jpg"
            style={{ color: "transparent" }}
          />
        </div>
        <div className="al_benefits_list_info">
          <h2>Professional Chauffeur Dubai </h2>
          <p>
            Hire chauffeur service with English-speaking chauffeurs in proper
            dress code.They have good local road knowledge for comfortable and
            and convenient drives
          </p>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 al_benefits_outer">
        <div className="al_benefits_list">
          <img
            alt=""
            loading="lazy"
            width={1080}
            height={1080}
            decoding="async"
            data-nimg={1}
            srcSet="assets/images/project/sp_3.jfif"
            src="assets/images/project/sp_3.jfif"
            style={{ color: "transparent" }}
          />
        </div>
        <div className="al_benefits_list_info">
          <h2>Hire Premium Business Chauffeur Service Dubai</h2>
          <p>
            Hire luxury chauffeur service in Dubai for your business .Rate
            inclusive of all services. No hidden charges.Lowest Rates Guarantee
            - Reliable Chauffeur Service
          </p>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 al_benefits_outer">
        <div className="al_benefits_list">
          <img
            alt=""
            loading="lazy"
            width={1080}
            height={1080}
            decoding="async"
            data-nimg={1}
            srcSet="assets/images/project/sp_4.jpg"
            src="assets/images/project/sp_4.jpg"
            style={{ color: "transparent" }}
          />
        </div>
        <div className="al_benefits_list_info">
          <h2>Affordable chauffeur service in Dubai</h2>
          <p>
            {" "}
            Book Luxury Chauffeur Service in Dubai.Get in touch via email,
            phone, live chat (24x7 service).
          </p>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 al_benefits_outer">
        <div className="al_benefits_list">
          <img
            alt=""
            loading="lazy"
            width={1080}
            height={1080}
            decoding="async"
            data-nimg={1}
            srcSet="assets/images/project/sp_6.jfif"
            src="assets/images/project/sp_6.jfif"
            style={{ color: "transparent" }}
          />
        </div>
        <div className="al_benefits_list_info">
          <h2>Book your chauffeur pick up and drop off service </h2>
          <p>
            Book now and receive an official quote in as little as 15 minutes
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
</section>

  {/*Testimonial One Start*/}
  <section className="testimonial-one"  >
    
    <div className="container" >
      <div className="testimonial-one__top" >
        <div className="row" style={{paddingTop:'40px'}}>
          <div className="col-xl-7 col-lg-6">
            <div className="section-title text-left">
              <div className="section-title__tagline-box">
                <span className="section-title__tagline">
                  Reviews &amp; Testimonials
                </span>
              </div>
              {/*<h2 class="section-title__title">Affordable Roofing Solutions <br>for Every Budget</h2>*/}
            </div>
          </div>
          {/*<div class="col-xl-5 col-lg-6">
                      <div class="testimonial-one__top-text">
                          <p>Dictum ultrices porttitor amet nec sollicitudin mi molestie adipiscing netus. Lorem
                              at ac ut morbi ullamcorper molestie lacus</p>
                      </div>
                  </div>*/}
        </div>
      </div>
      <div className="testimonial-one__bottom">
        <div className="row">
          <div className="col-xl-4 col-lg-5">
            <div
              className="testimonial-one__img-box wow slideInLeft"
              data-wow-delay="100ms"
              data-wow-duration="2500ms"
            >
              <div className="testimonial-one__img">
                <img
                  src="assets/images/testimonial/testimonial-one-img-1.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="col-xl-8 col-lg-7">
            <div className="testimonial-one__bottom-right">
              <div className="testimonial-one__nav">
                <div className="swiper-button-next1">
                  <i className="icon-arrow-right" />
                </div>
                <div className="swiper-button-prev1">
                  <i className="icon-arrow-left" />
                </div>
              </div>
              <div
                className="thm-swiper__slider swiper-container"
                data-swiper-options='{
                              "slidesPerView": 1, 
                              "spaceBetween": 0,
                              "speed": 2000,
                              "loop": true,
                              "pagination": {
                                  "el": ".swiper-dot-style1",
                                  "type": "bullets",
                                  "clickable": true
                              },
      
                              
      
                              "navigation": {
                                  "nextEl": ".swiper-button-prev1",
                                  "prevEl": ".swiper-button-next1"
                              },
                              "autoplay": { "delay": 9000 },
                              "breakpoints": {
                                  "0": {
                                      "spaceBetween": 0,
                                      "slidesPerView": 1
                                  },
                                  "375": {
                                      "spaceBetween": 0,
                                      "slidesPerView": 1
                                  },
                                  "575": {
                                      "spaceBetween": 0,
                                      "slidesPerView": 1
                                  },
                                  "768": {
                                      "spaceBetween": 30,
                                      "slidesPerView": 1
                                  },
                                  "992": {
                                      "spaceBetween": 30,
                                      "slidesPerView": 1
                                  },
                                  "1200": {
                                      "spaceBetween": 30,
                                      "slidesPerView": 1
                                  },
                                  "1320": {
                                      "spaceBetween": 30,
                                      "slidesPerView": 1
                                  }
                              }
                          }'
              >
                <div className="swiper-wrapper">
                  {/*Testimonial One Single Start*/}
                  <div className="swiper-slide">
                    <div className="testimonial-one__single">
                      <div className="testimonial-one__quote">
                        <span className="icon-quote" />
                      </div>
                      <div className="testimonial-one__ratting">
                        <span className="icon-star" />
                        <span className="icon-star" />
                        <span className="icon-star" />
                        <span className="icon-star" />
                        <span className="icon-star last-icon" />
                      </div>
                      <p className="testimonial-one__text">
                        Amazing experience by the Riz Chauffeur Transportation
                        team. Special thanks goes to Mr. Rosary Lavin for
                        scheduling and organizing our trip from our villa in Al
                        Ain to Dubai Airport. We had initially opted for the
                        economy class vegicle. Mr. Rosary also, generously,
                        offered a larger vehicle as a company courtesy as we had
                        a large number of luggage. A BIG thank you for that.
                        Another special thanks goes to our Chauffeur.
                      </p>
                      <div className="testimonial-one__client-info">
                        <div className="testimonial-one__client-img">
                          <img
                            src="assets/images/testimonial/testimonial-one-client-img-1.png"
                            alt=""
                          />
                        </div>
                        <div className="testimonial-one__client-content">
                          <h3>
                            <a href="">Michael Ramirez</a>
                          </h3>
                          <p>Marketing Manager</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*Testimonial One Single End*/}
                  {/*Testimonial One Single Start*/}
                  <div className="swiper-slide">
                    <div className="testimonial-one__single">
                      <div className="testimonial-one__quote">
                        <span className="icon-quote" />
                      </div>
                      <div className="testimonial-one__ratting">
                        <span className="icon-star" />
                        <span className="icon-star" />
                        <span className="icon-star" />
                        <span className="icon-star" />
                        <span className="icon-star last-icon" />
                      </div>
                      <p className="testimonial-one__text">
                        I recently had the pleasure of using Riz Chauffeurs for
                        my transportation needs in Dubai, and I couldn't be more
                        impressed. From the very beginning, they exceeded my
                        expectations.
                      </p>
                      <div className="testimonial-one__client-info">
                        <div className="testimonial-one__client-img">
                          <img
                            src="assets/images/testimonial/testimonial-one-client-img-2.png"
                            alt=""
                          />
                        </div>
                        <div className="testimonial-one__client-content">
                          <h3>
                            <a href="">Robert Son</a>
                          </h3>
                          <p>Marketing Manager</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*Testimonial One Single End*/}
                  {/*Testimonial One Single Start*/}
                  <div className="swiper-slide">
                    <div className="testimonial-one__single">
                      <div className="testimonial-one__quote">
                        <span className="icon-quote" />
                      </div>
                      <div className="testimonial-one__ratting">
                        <span className="icon-star" />
                        <span className="icon-star" />
                        <span className="icon-star" />
                        <span className="icon-star" />
                        <span className="icon-star last-icon" />
                      </div>
                      <p className="testimonial-one__text">
                        We always use Riz chauffeur service for our offical
                        requirements and they give excellent service and have
                        always assisted us on short notices too. We have hired
                        all car categories and they have amazing fleet and
                        service.
                      </p>
                      <div className="testimonial-one__client-info">
                        <div className="testimonial-one__client-img">
                          <img
                            src="assets/images/testimonial/testimonial-one-client-img-3.png"
                            alt=""
                          />
                        </div>
                        <div className="testimonial-one__client-content">
                          <h3>
                            <a href="">David Coper</a>
                          </h3>
                          <p>Marketing Manager</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*Testimonial One Single End*/}
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*Testimonial One End*/}
  {/*Blog One Start*/}
  <section className="blog-one">
    <div className="container">
      <div className="section-title text-center">
        <div className="section-title__tagline-box">
          <span className="section-title__tagline">Blogs</span>
        </div>
        {/*<h2 class="section-title__title">Toyota HiAce Chauffeur<br> Hire Van Hire Dubai </h2>*/}
      </div>
      <div className="row">
        <div className="col-xl-6 col-lg-6 wow fadeInUp" data-wow-delay="100ms">
          <div className="blog-one__single">
            <div className="blog-one__img-box">
              <div className="blog-one__img">
                <img
                  src="assets/images/news/blog-1-1.jpg"
                  alt="@@title"
                  width={630}
                  height={320}
                />
                <img src="assets/images/news/blog-1-1.jpg" alt="@@title" />
                <a href="" className="blog-one__link">
                  <span className="sr-only" />
                </a>
              </div>
              <div className="blog-one__date">
                <p>
                  16<span>Mar</span>
                </p>
              </div>
            </div>
            <div className="blog-one__content">
              <ul className="blog-one__meta list-unstyled">
                <li>
                  <a href="">
                    <span className="icon-comment" />
                    Comments (05)
                  </a>
                </li>
                <li>
                  <a href="">
                    <span
                      className="icon-user
                                      "
                    />
                    By admin
                  </a>
                </li>
              </ul>
              <h3 className="blog-one__title">
                <a href="">
                  Toyota HiAce Chauffeur Hire Van Hire Dubai{" "}
                </a>
              </h3>
              <p className="blog-one__text">
                Toyota HiAce chauffeur-driven car hire is available in Dubai and
                throughout the UAE from Chauffeur Car Hire Dubai, delivering
                world-class chauffeur services for both business and leisure
                travel.
              </p>
              <div className="blog-one__read-more">
                <a href="">
                  Read More
                  <span className="icon-right-arrow" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 wow fadeInUp" data-wow-delay="200ms">
          <div className="blog-one__single">
            <div className="blog-one__img-box">
              <div className="blog-one__img">
                <img
                  src="assets/images/news/blog-1-2.jpg"
                  alt="@@title"
                  width={630}
                  height={320}
                />
                <img src="assets/images/news/blog-1-2.jpg" alt="@@title" />
                <a href="" className="blog-one__link">
                  <span className="sr-only" />
                </a>
              </div>
              <div className="blog-one__date">
                <p>
                  16<span>Mar</span>
                </p>
              </div>
            </div>
            <div className="blog-one__content">
              <ul className="blog-one__meta list-unstyled">
                <li>
                  <a href="">
                    <span className="icon-comment" />
                    Comments (05)
                  </a>
                </li>
                <li>
                  <a href="">
                    <span
                      className="icon-user
                                      "
                    />
                    By admin
                  </a>
                </li>
              </ul>
              <h3 className="blog-one__title">
                <a href="">Limousine Service in Dubai </a>
              </h3>
              <p className="blog-one__text">
                Experience the epitome of luxury by hiring one of our exquisite
                limousine cars with a professional chauffeur. Sit back, relax,
                and enjoy the journey like a true VIP{" "}
              </p>
              <div className="blog-one__read-more">
                <a href="">
                  Read More
                  <span className="icon-right-arrow" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*Blog One End*/}


  <section
    className=""
    style={{ backgroundColor: "#fff", paddingTop: 50 }}
  >
    <div className="container" >
      <div className="section-title text-center" style={{ marginBottom: 0 }}>
        <div className="section-title__tagline-box">
          <span className="section-title__tagline" style={{ color: "black" }}>
           Brands
          </span>
        </div>
        <br />
        <img
          alt=""
          loading="lazy"
          width={97}
          height={13}
          decoding="async"
          data-nimg={1}
          style={{ color: "transparent" }}
          src="assets/images/svg/separator.eb50640e.svg"
        />
      </div>
      <br />
      <div className="slick-slider slick-initialized">
  <div className="slick-list">
    <div
      className="slick-track"
      style={{
        opacity: 1,
        transform: "translate3d(0px, 0px, 0px)",
        width: 1380
      }}
    >
        <div class="">

    <div class="scroll-container">
      <div class="carousel-primary">
      <div
        data-index={2}
        className="slick-slide"
        tabIndex={-1}
        aria-hidden="true"
        style={{ outline: "none", width: 230 }}
      >
        <div>
          <div tabIndex={-1} style={{ width: "100%", display: "inline-block" }}>
          <img
              title="Range Rover"
              alt="Range Rover"
              loading="lazy"
              width={384}
              height={216}
              decoding="async"
              data-nimg={1}
              
              src="assets/images/brand/riz-rollsroyce-car-logo.png"
              style={{ color: "transparent" }}
            />
          </div>
        </div>
      </div>
      <div
        data-index={3}
        className="slick-slide"
        tabIndex={-1}
        aria-hidden="true"
        style={{ outline: "none", width: 230 }}
      >
        <div>
          <div tabIndex={-1} style={{ width: "100%", display: "inline-block" }}>
          <img
              title="Range Rover"
              alt="Range Rover"
              loading="lazy"
              width={384}
              height={216}
              decoding="async"
              data-nimg={1}
              
              src="assets/images/brand/riz-Lexus-car-logo.png"
              style={{ color: "transparent" }}
            />
          </div>
        </div>
      </div>
      <div
        data-index={4}
        className="slick-slide slick-active slick-current"
        tabIndex={-1}
        aria-hidden="false"
        style={{ outline: "none", width: 230 }}
      >
        <div>
          <div tabIndex={-1} style={{ width: "100%", display: "inline-block" }}>
          <img
              title="Range Rover"
              alt="Range Rover"
              loading="lazy"
              width={384}
              height={216}
              decoding="async"
              data-nimg={1}
              
              src="assets/images/brand/riz-gmc-car-logo.png"
              style={{ color: "transparent" }}
            />
          </div>
        </div>
      </div>
      <div
        data-index={5}
        className="slick-slide slick-active"
        tabIndex={-1}
        aria-hidden="false"
        style={{ outline: "none", width: 230 }}
      >
        <div>
          <div tabIndex={-1} style={{ width: "100%", display: "inline-block" }}>
          <img
              title="Range Rover"
              alt="Range Rover"
              loading="lazy"
              width={384}
              height={216}
              decoding="async"
              data-nimg={1}
              
              src="assets/images/brand/riz-bmw-car-logo.png"
              style={{ color: "transparent" }}
            />
          </div>
        </div>
      </div>
      <div
        data-index={1}
        className="slick-slide"
        tabIndex={-1}
        aria-hidden="true"
        style={{ outline: "none", width: 230 }}
      >
        <div>
          <div tabIndex={-1} style={{ width: "100%", display: "inline-block" }}>
          <img
              title="Range Rover"
              alt="Range Rover"
              loading="lazy"
              width={384}
              height={216}
              decoding="async"
              data-nimg={1}
              
              src="assets/images/brand/riz-audi-car-logo.png"
              style={{ color: "transparent" }}
            />
          </div>
        </div>
      </div>
      </div>
      <div class="carousel-primary carousel-secondary">
      <div
        data-index={2}
        className="slick-slide"
        tabIndex={-1}
        aria-hidden="true"
        style={{ outline: "none", width: 230 }}
      >
        <div>
          <div tabIndex={-1} style={{ width: "100%", display: "inline-block" }}>
          <img
              title="Range Rover"
              alt="Range Rover"
              loading="lazy"
              width={384}
              height={216}
              decoding="async"
              data-nimg={1}
              
              src="assets/images/brand/riz-rollsroyce-car-logo.png"
              style={{ color: "transparent" }}
            />
          </div>
        </div>
      </div>
      <div
        data-index={3}
        className="slick-slide"
        tabIndex={-1}
        aria-hidden="true"
        style={{ outline: "none", width: 230 }}
      >
        <div>
          <div tabIndex={-1} style={{ width: "100%", display: "inline-block" }}>
          <img
              title="Range Rover"
              alt="Range Rover"
              loading="lazy"
              width={384}
              height={216}
              decoding="async"
              data-nimg={1}
              
              src="assets/images/brand/riz-Lexus-car-logo.png"
              style={{ color: "transparent" }}
            />
          </div>
        </div>
      </div>
      <div
        data-index={4}
        className="slick-slide slick-active slick-current"
        tabIndex={-1}
        aria-hidden="false"
        style={{ outline: "none", width: 230 }}
      >
        <div>
          <div tabIndex={-1} style={{ width: "100%", display: "inline-block" }}>
          <img
              title="Range Rover"
              alt="Range Rover"
              loading="lazy"
              width={384}
              height={216}
              decoding="async"
              data-nimg={1}
              
              src="assets/images/brand/riz-gmc-car-logo.png"
              style={{ color: "transparent" }}
            />
          </div>
        </div>
      </div>
      <div
        data-index={5}
        className="slick-slide slick-active"
        tabIndex={-1}
        aria-hidden="false"
        style={{ outline: "none", width: 230 }}
      >
        <div>
          <div tabIndex={-1} style={{ width: "100%", display: "inline-block" }}>
          <img
              title="Range Rover"
              alt="Range Rover"
              loading="lazy"
              width={384}
              height={216}
              decoding="async"
              data-nimg={1}
              
              src="assets/images/brand/riz-bmw-car-logo.png"
              style={{ color: "transparent" }}
            />
          </div>
        </div>
      </div>
      <div
        data-index={1}
        className="slick-slide"
        tabIndex={-1}
        aria-hidden="true"
        style={{ outline: "none", width: 230 }}
      >
        <div>
          <div tabIndex={-1} style={{ width: "100%", display: "inline-block" }}>
          <img
              title="Range Rover"
              alt="Range Rover"
              loading="lazy"
              width={384}
              height={216}
              decoding="async"
              data-nimg={1}
              
              src="assets/images/brand/riz-audi-car-logo.png"
              style={{ color: "transparent" }}
            />
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
    </div>
  </div>
</div>

    </div>
    <br/>
<br/>
  </section>
<div style={{border:'1px solid silver'}}></div>
  <section
    className=""
    style={{ backgroundColor: "#fff" }}
  >
    <div className="container" >
      <div className="section-title text-center" style={{ marginBottom: 0 }}>
        <div className="section-title__tagline-box">
          <span className="section-title__tagline pt-5" style={{ color: "black" }}>
           Our Customers
          </span>
        </div>
        <br />
        <img
          alt=""
          loading="lazy"
          width={97}
          height={13}
          decoding="async"
          data-nimg={1}
          style={{ color: "transparent" }}
          src="assets/images/svg/separator.eb50640e.svg"
        />
      </div>
      <br />
      <div className="slick-slider slick-initialized">
  <div className="slick-list">
    <div
      className="slick-track"
      style={{
        opacity: 1,
        transform: "translate3d(0px, 0px, 0px)",
        width: 1380
      }}
    >
         <div class="">
    
    <div class="scroll-container">
      <div class="carousel-primary">
      <div
        data-index={0}
        className="slick-slide"
        tabIndex={-1}
        aria-hidden="true"
        style={{ outline: "none", width: 230 }}
      >
        
        <div>
          <div tabIndex={-1} style={{ width: "100%", display: "inline-block" }}>
            <img
              title="Range Rover"
              alt="Range Rover"
              loading="lazy"
              width={384}
              height={216}
              decoding="async"
              data-nimg={1}
              
              src="assets/images/customer/Cus_7.jpg"
              style={{ color: "transparent" }}
            />
          </div>
        </div>
      </div>
      <div
        data-index={1}
        className="slick-slide"
        tabIndex={-1}
        aria-hidden="true"
        style={{ outline: "none", width: 230 }}
      >
        <div>
          <div tabIndex={-1} style={{ width: "100%", display: "inline-block" }}>
          <img
              title="Range Rover"
              alt="Range Rover"
              loading="lazy"
              width={384}
              height={216}
              decoding="async"
              data-nimg={1}
              
              src="assets/images/customer/Cus_2.jpg"
              style={{ color: "transparent" }}
            />
          </div>
        </div>
      </div>
      <div
        data-index={2}
        className="slick-slide"
        tabIndex={-1}
        aria-hidden="true"
        style={{ outline: "none", width: 230 }}
      >
        <div>
          <div tabIndex={-1} style={{ width: "100%", display: "inline-block" }}>
          <img
              title="Range Rover"
              alt="Range Rover"
              loading="lazy"
              width={384}
              height={216}
              decoding="async"
              data-nimg={1}
              
              src="assets/images/customer/Cus_3.jpg"
              style={{ color: "transparent" }}
            />
          </div>
        </div>
      </div>
      <div
        data-index={3}
        className="slick-slide"
        tabIndex={-1}
        aria-hidden="true"
        style={{ outline: "none", width: 230 }}
      >
        <div>
          <div tabIndex={-1} style={{ width: "100%", display: "inline-block" }}>
          <img
              title="Range Rover"
              alt="Range Rover"
              loading="lazy"
              width={384}
              height={216}
              decoding="async"
              data-nimg={1}
              
              src="assets/images/customer/Cus_5.jpg"
              style={{ color: "transparent" }}
            />
          </div>
        </div>
      </div>
      <div
        data-index={4}
        className="slick-slide slick-active slick-current"
        tabIndex={-1}
        aria-hidden="false"
        style={{ outline: "none", width: 230 }}
      >
        <div>
          <div tabIndex={-1} style={{ width: "100%", display: "inline-block" }}>
          <img
              title="Range Rover"
              alt="Range Rover"
              loading="lazy"
              width={384}
              height={216}
              decoding="async"
              data-nimg={1}
              
              src="assets/images/customer/Cus_6.jpg"
              style={{ color: "transparent" }}
            />
          </div>
        </div>
      </div>
     
      </div>
      <div class="carousel-primary carousel-secondary">
      <div
        data-index={0}
        className="slick-slide"
        tabIndex={-1}
        aria-hidden="true"
        style={{ outline: "none", width: 230 }}
      >
        
        <div>
          <div tabIndex={-1} style={{ width: "100%", display: "inline-block" }}>
            <img
              title="Range Rover"
              alt="Range Rover"
              loading="lazy"
              width={384}
              height={216}
              decoding="async"
              data-nimg={1}
              
              src="assets/images/customer/Cus_7.jpg"
              style={{ color: "transparent" }}
            />
          </div>
        </div>
      </div>
      <div
        data-index={1}
        className="slick-slide"
        tabIndex={-1}
        aria-hidden="true"
        style={{ outline: "none", width: 230 }}
      >
        <div>
          <div tabIndex={-1} style={{ width: "100%", display: "inline-block" }}>
          <img
              title="Range Rover"
              alt="Range Rover"
              loading="lazy"
              width={384}
              height={216}
              decoding="async"
              data-nimg={1}
              
              src="assets/images/customer/Cus_2.jpg"
              style={{ color: "transparent" }}
            />
          </div>
        </div>
      </div>
      <div
        data-index={2}
        className="slick-slide"
        tabIndex={-1}
        aria-hidden="true"
        style={{ outline: "none", width: 230 }}
      >
        <div>
          <div tabIndex={-1} style={{ width: "100%", display: "inline-block" }}>
          <img
              title="Range Rover"
              alt="Range Rover"
              loading="lazy"
              width={384}
              height={216}
              decoding="async"
              data-nimg={1}
              
              src="assets/images/customer/Cus_3.jpg"
              style={{ color: "transparent" }}
            />
          </div>
        </div>
      </div>
      <div
        data-index={3}
        className="slick-slide"
        tabIndex={-1}
        aria-hidden="true"
        style={{ outline: "none", width: 230 }}
      >
        <div>
          <div tabIndex={-1} style={{ width: "100%", display: "inline-block" }}>
          <img
              title="Range Rover"
              alt="Range Rover"
              loading="lazy"
              width={384}
              height={216}
              decoding="async"
              data-nimg={1}
              
              src="assets/images/customer/Cus_5.jpg"
              style={{ color: "transparent" }}
            />
          </div>
        </div>
      </div>
      <div
        data-index={4}
        className="slick-slide slick-active slick-current"
        tabIndex={-1}
        aria-hidden="false"
        style={{ outline: "none", width: 230 }}
      >
        <div>
          <div tabIndex={-1} style={{ width: "100%", display: "inline-block" }}>
          <img
              title="Range Rover"
              alt="Range Rover"
              loading="lazy"
              width={384}
              height={216}
              decoding="async"
              data-nimg={1}
              
              src="assets/images/customer/Cus_6.jpg"
              style={{ color: "transparent" }}
            />
          </div>
        </div>
      </div>
      
      </div>
    </div>
  </div>
     
    </div>
  </div>
</div>

    </div>
    <br/>
<br/>
  </section>

  <>
  {/* /.page-wrapper */}
  <div className="mobile-nav__wrapper">
    <div className="mobile-nav__overlay mobile-nav__toggler" />
    {/* /.mobile-nav__overlay */}
    <div className="mobile-nav__content">
      <span className="mobile-nav__close mobile-nav__toggler">
        <i className="fa fa-times" />
      </span>
      <div className="logo-box">
        <a href="/" style={{ paddingLeft: "0PX" }}>
          <img
            src="assets/images/resources/logosmall.png"
            height="80px"
            style={{ marginLeft: "-40px" }}
            alt=""
          />
        </a>
      </div>
      {/* /.logo-box */}
      <div className="mobile-nav__container" />
      {/* /.mobile-nav__container */}
      <ul className="mobile-nav__contact list-unstyled">
        <li>
          <i className="fa fa-envelope" />
          <a href="mailto:needhelp@packageName__.com">
            booking@rizchauffeur.com
          </a>
        </li>
        <li>
          <i className="fa fa-phone-alt" />
          <a href="tel:666-888-0000">+971 563847412</a>
        </li>
      </ul>
      {/* /.mobile-nav__contact */}
      <div className="mobile-nav__top">
        <div className="mobile-nav__social">
          <a href="#" className="fab fa-twitter" />
          <a href="#" className="fab fa-facebook-square" />
          <a href="#" className="fab fa-pinterest-p" />
          <a href="#" className="fab fa-instagram" />
        </div>
        {/* /.mobile-nav__social */}
      </div>
      {/* /.mobile-nav__top */}
    </div>
    {/* /.mobile-nav__content */}
  </div>
  {/* /.mobile-nav__wrapper */}
</>


  <div className="scroll-to-top">
    <svg className="scroll-top-inner" viewBox="-1 -1 102 102">
        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
    </svg>
</div>
        </div>



    );
}
export default Index;