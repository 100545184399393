import { Link } from "react-router-dom";
import React,{useEffect,useState,useRef} from 'react';

function BusRental() {
  useEffect(() => {
    window.scroll(0, 0);
    
},[]);
    return (
        <div className="page-wrapper" style={{backgroundColor:'white'}}>
        {/*Page Header Start*/}
<section className="page-header">
<div
  className="page-header__bg"
  style={{
    backgroundImage: "url(assets/images/backgrounds/slider-1-2.jpg)"
  }}
></div>
<div className="container">
  <div className="page-header__inner">
    <h2>Bus Rental</h2>
    <div className="thm-breadcrumb__box">
      <ul className="thm-breadcrumb list-unstyled">
        <li>
          <a href="/">Home</a>
        </li>
        <li>
          <span className="icon-arrow-left" />
        </li>
        <li>Bus Rental</li>
      </ul>
    </div>
  </div>
</div>
</section>

<>
  {/*Service Details Start*/}
  <section className="" style={{paddingTop:'50px'}}>
    <div className="container">
      <div className="row">
        <div className="col-xl-8 col-lg-7">
          <div className="service-details__left">
            <div className="service-details__img-one">
              <img
                src="assets/images/services/busrestal-1.jpg"
                alt=""
              />
            </div>
            <div className="service-details__content-one">
              <h4 className="service-details__title-one">Bus Rental | Minibus Rental  | Van rental </h4>
             
              <ul className="list-unstyled service-details__points">
                <li>
                  <div className="circle" />
                  <div className="text">
                    <p>
                    Discover ultimate luxury while exploring.
                    </p>
                  </div>
                </li>
                <li>
                  <div className="circle" />
                  <div className="text">
                    <p>
                    The bustling Dubai city can be explored with our high-end bus rental services.
                    </p>
                  </div>
                </li>
                <li>
                  <div className="circle" />
                  <div className="text">
                    <p>Intended for big crowds and functions. Book with us to enhance your urban experience.</p>
                  </div>
                </li>
                <li>
                  <div className="circle" />
                  <div className="text">
                    <p>Reaching new heights on the tour.</p>
                  </div>
                </li>
                <li>
                  <div className="circle" />
                  <div className="text">
                    <p>Choose our high-end bus hire services in Dubai for a luxurious experience.</p>
                  </div>
                </li>
                <li>
                  <div className="circle" />
                  <div className="text">
                    <p>Improve your travel experience with unmatched convenience and luxury, designed for you.</p>
                  </div>
                </li>
                <li>
                  <div className="circle" />
                  <div className="text">
                    <p>The services provided were created to guarantee a worry-free journey, enabling you to unwind and savor.</p>
                  </div>
                </li>
                <li>
                  <div className="circle" />
                  <div className="text">
                    <p>Each and every moment. Apart from luxury buses, we also provide mini-buses and vans.</p>
                  </div>
                </li>
                <li>
                  <div className="circle" />
                  <div className="text">
                    <p>The facility can cater to groups of all sizes.</p>
                  </div>
                </li>
                <li>
                  <div className="circle" />
                  <div className="text">
                    <p>Rent a luxury bus in Dubai for a luxurious experience. Luxury buses available for rental in Dubai.</p>
                  </div>
                </li>
                <li>
                  <div className="circle" />
                  <div className="text">
                    <p>Arranging group outings and activities with bus rentals in Dubai provides countless benefits. Choosing to rent a bus for events like school trips, business functions, weddings, or other group outings is a smart and convenient option.</p>
                  </div>
                </li>
                <li>
                  <div className="circle" />
                  <div className="text">
                    <p>Public transportation may not always provide a comfortable experience.</p>
                  </div>
                </li>
                <li>
                  <div className="circle" />
                  <div className="text">
                    <p>Private taxis are frequently not practical because of their expensive rates, whereas convenience is often associated with ride-sharing services.</p>
                  </div>
                </li>
                <li>
                  <div className="circle" />
                  <div className="text">
                    <p>Furthermore, regular cars are not suitable for carrying large numbers of people.</p>
                  </div>
                </li>
                <li>
                  <div className="circle" />
                  <div className="text">
                    <p>Individuals. Because of these difficulties, organizing and carrying out trips can become problematic. Choosing a luxury bus rental in Dubai is the perfect choice. Renting a bus is a more economical option for large groups compared to other transportation choices, offering luxury, comfort, and a safe experience for everyone in the group.</p>
                  </div>
                </li>
                <li>
                  <div className="circle" />
                  <div className="text">
                    <p>Improving road safety by reducing the likelihood of accidents.</p>
                  </div>
                </li>
              </ul>
            </div>
           
          </div>
        </div>
        <div className="col-xl-4 col-lg-5">
          <div className="service-details__sidebar">
            <div className="service-details__service-list-box">
              <h4 className="service-details__sidebar-title">
                Our All Service
              </h4>
              <ul className="service-details__service-list list-unstyled">
                <li className="active">
                  <Link to="/airporttransfer">
                  Airport Transfer
                    <span className="icon-right-arrow" />
                  </Link>
                </li>
                <li>
                  <Link to="/busrental">
                  Bus | Minibus | Van rental
                    <span className="icon-right-arrow" />
                  </Link>
                </li>
                <li>
                  <Link to="/chauffeurservices">
                  Chauffeur Service
                    <span className="icon-right-arrow" />
                  </Link>
                </li>
                <li>
                  <Link to="/limousineservice">
Limousine Service Dubai
                    <span className="icon-right-arrow" />
                  </Link>
                </li>
                <li>
                  <Link to="/hourlyrateservice">
                  Hourly Rate Service
                    <span className="icon-right-arrow" />
                  </Link>
                </li>
                <li>
                  <Link to="/groundtransportation">
                  Ground Transportation
                    <span className="icon-right-arrow" />
                  </Link>
                </li>
              </ul>
            </div>
            <div className="service-details__need-help">
              <div className="service-details__need-help-icon">
                <span className="icon-call" />
              </div>
              <p className="service-details__need-help-sub-title">Need help?</p>
              <h5 className="service-details__need-help-number">
                <a href="tel:8085550111">+971 563847412</a>
              </h5>
              <p className="service-details__need-help-text">
              Email : booking@rizchauffeur.com
              </p>
            </div>
            <div className="service-details__brochure-box" style={{display:'none'}}>
              <h5 className="service-details__brochure-box-title">Brochure</h5>
              <ul className="list-unstyled service-details__brochure-list">
                <li>
                  <div className="icon">
                    <span className="icon-file" />
                  </div>
                  <div className="content">
                    <h5>
                      <a href="#">Download Brochure</a>
                    </h5>
                    <p>
                      orem Ipsum is simply is dumiomy is tex Lorem Ipsum is
                      simply is ou our
                    </p>
                  </div>
                </li>
                <li>
                  <div className="icon">
                    <span className="icon-file" />
                  </div>
                  <div className="content">
                    <h5>
                      <a href="#">Company Details</a>
                    </h5>
                    <p>
                      orem Ipsum is simply is dumiomy is tex Lorem Ipsum is
                      simply is ou our
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </section>
  <br></br>
  {/*Service Details End*/}
</>

 {/* /.page-wrapper */}
 <div className="mobile-nav__wrapper">
    <div className="mobile-nav__overlay mobile-nav__toggler" />
    {/* /.mobile-nav__overlay */}
    <div className="mobile-nav__content">
      <span className="mobile-nav__close mobile-nav__toggler">
        <i className="fa fa-times" />
      </span>
      <div className="logo-box">
        <a href="/" style={{ paddingLeft: "0PX" }}>
          <img
            src="assets/images/resources/logosmall.png"
            height="80px"
            style={{ marginLeft: "-40px" }}
            alt=""
          />
        </a>
      </div>
      {/* /.logo-box */}
      <div className="mobile-nav__container" />
      {/* /.mobile-nav__container */}
      <ul className="mobile-nav__contact list-unstyled">
        <li>
          <i className="fa fa-envelope" />
          <a href="mailto:needhelp@packageName__.com">
            booking@rizchauffeur.com
          </a>
        </li>
        <li>
          <i className="fa fa-phone-alt" />
          <a href="tel:666-888-0000">+971 563847412</a>
        </li>
      </ul>
      {/* /.mobile-nav__contact */}
      <div className="mobile-nav__top">
        <div className="mobile-nav__social">
          <a href="#" className="fab fa-twitter" />
          <a href="#" className="fab fa-facebook-square" />
          <a href="#" className="fab fa-pinterest-p" />
          <a href="#" className="fab fa-instagram" />
        </div>
        {/* /.mobile-nav__social */}
      </div>
      {/* /.mobile-nav__top */}
    </div>
    {/* /.mobile-nav__content */}
  </div>
  {/* /.mobile-nav__wrapper */}
  
  <div className="scroll-to-top">
    <svg className="scroll-top-inner" viewBox="-1 -1 102 102">
        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
    </svg>
</div>
</div>
    )
};
export default BusRental;