import { Link } from "react-router-dom";
import React,{useEffect,useState,useRef} from 'react';

function AirportTransfer() {

  useEffect(() => {
    window.scroll(0, 0);
    
},[]);

    return (
        <div className="page-wrapper" style={{backgroundColor:'white'}}>
        {/*Page Header Start*/}
<section className="page-header">
<div
  className="page-header__bg"
  style={{
    backgroundImage: "url(assets/images/backgrounds/slider-1-2.jpg)"
  }}
></div>
<div className="container">
  <div className="page-header__inner">
    <h2>Airport Transfer</h2>
    <div className="thm-breadcrumb__box">
      <ul className="thm-breadcrumb list-unstyled">
        <li>
          <a href="/">Home</a>
        </li>
        <li>
          <span className="icon-arrow-left" />
        </li>
        <li>Airport Transfer</li>
      </ul>
    </div>
  </div>
</div>
</section>

<>
  {/*Service Details Start*/}
  <section className="" style={{paddingTop:'50px'}}>
    <div className="container">
      <div className="row">
        <div className="col-xl-8 col-lg-7">
          <div className="service-details__left">
            <div className="service-details__img-one">
              <img
                src="assets/images/services/airporttransfer-2.jpg"
                alt=""
              />
            </div>
            <div className="service-details__content-one">
              <h4 className="service-details__title-one">Airport Transfer</h4>
              <p className="service-details__text-1">
              By offering chauffeur service and airport limousine service in Dubai, we aim to ensure a hassle-free 
              and efficient experience during your stay in the city. Riz Chauffeur provides high-end Airport Transportation 
              services for seamless transfers. If you require a chauffeur, delivery services, or any other requests 
              for your arrival or departure, our representatives will assist you with everything.
              </p>
              <h4 className="service-details__title-one">
              Meet and greet our Chauffeur Service Dubai
              </h4>
              <p className="service-details__text-2">
              This is the initial stage of our Dubai chauffeur service designed to simplify your airport transportation. 
              Traveling can often cause a lot of stress and inconvenience, which is why we aim to alleviate some of that burden for you. 
              Our chauffeur and airport representative will be there to greet you prior to your arrival. 
              Our skilled driver will accompany you to and from the terminal, helping with your bags, to ensure your arrival and 
              departures are as smooth as can be. We offer top-notch luxury airport transfers from Dubai, Abu Dhabi, and Sharjah. 
              Click to schedule your upcoming trip, at any hour of the day or night, all day, every day. The algorithms in our Dispatch 
              Center are programmed to monitor flights to ensure our chauffeurs arrive early. 
              Monitor your journey live with instant updates using FAA Global tracking and GPS vehicle tracking.
              </p>
              <h4 className="service-details__title-one">
              Safe Drives
              </h4>
              <p className="service-details__text-2">
              At Riz Chauffeur, we have elevated the screening and training process for our chauffeurs to a higher level. 
              This guarantees that clients are provided with top chauffeurs who have undergone background checks, 
              reviewed DMV records, completed safety driving courses, and obtained CPR and first aid certification. 
              Additionally, if you are traveling with children, we provide safety seats that are appropriate for their size upon request. 
              We offer luxury chauffeur services for individuals as well as large corporations. 
              Each vehicle undergoes a thorough examination by our expert team of certified mechanics and detailers before being added to 
              our collection, which includes a 40-point inspection carried out twice daily and quarterly.
              </p>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-5">
          <div className="service-details__sidebar">
            <div className="service-details__service-list-box">
              <h4 className="service-details__sidebar-title">
                Our All Service
              </h4>
              <ul className="service-details__service-list list-unstyled">
                <li className="active">
                  <Link to="/airporttransfer">
                  Airport Transfer
                    <span className="icon-right-arrow" />
                  </Link>
                </li>
                <li>
                  <Link to="/busrental">
                  Bus | Minibus | Van rental
                    <span className="icon-right-arrow" />
                  </Link>
                </li>
                <li>
                  <Link to="/chauffeurservices">
                  Chauffeur Service
                    <span className="icon-right-arrow" />
                  </Link>
                </li>
                <li>
                  <Link to="/limousineservice">
Limousine Service Dubai
                    <span className="icon-right-arrow" />
                  </Link>
                </li>
                <li>
                  <Link to="/hourlyrateservice">
                    Hourly Rate Service
                    <span className="icon-right-arrow" />
                  </Link>
                </li>
                <li>
                  <Link to="/groundtransportation">
                  Ground Transportation
                    <span className="icon-right-arrow" />
                  </Link>
                </li>
              </ul>
            </div>
            <div className="service-details__need-help">
              <div className="service-details__need-help-icon">
                <span className="icon-call" />
              </div>
              <p className="service-details__need-help-sub-title">Need help?</p>
              <h5 className="service-details__need-help-number">
                <a href="tel:8085550111">+971 563847412</a>
              </h5>
              <p className="service-details__need-help-text">
                Email : booking@rizchauffeur.com
              </p>
            </div>
            <div className="service-details__brochure-box" style={{display:'none'}}>
              <h5 className="service-details__brochure-box-title">Brochure</h5>
              <ul className="list-unstyled service-details__brochure-list">
                <li>
                  <div className="icon">
                    <span className="icon-file" />
                  </div>
                  <div className="content">
                    <h5>
                      <a href="#">Download Brochure</a>
                    </h5>
                    <p>
                      orem Ipsum is simply is dumiomy is tex Lorem Ipsum is
                      simply is ou our
                    </p>
                  </div>
                </li>
                <li>
                  <div className="icon">
                    <span className="icon-file" />
                  </div>
                  <div className="content">
                    <h5>
                      <a href="#">Company Details</a>
                    </h5>
                    <p>
                      orem Ipsum is simply is dumiomy is tex Lorem Ipsum is
                      simply is ou our
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </section>
  <br></br>
  {/*Service Details End*/}
</>


 {/* /.page-wrapper */}
 <div className="mobile-nav__wrapper">
    <div className="mobile-nav__overlay mobile-nav__toggler" />
    {/* /.mobile-nav__overlay */}
    <div className="mobile-nav__content">
      <span className="mobile-nav__close mobile-nav__toggler">
        <i className="fa fa-times" />
      </span>
      <div className="logo-box">
        <a href="/" style={{ paddingLeft: "0PX" }}>
          <img
            src="assets/images/resources/logosmall.png"
            height="80px"
            style={{ marginLeft: "-40px" }}
            alt=""
          />
        </a>
      </div>
      {/* /.logo-box */}
      <div className="mobile-nav__container" />
      {/* /.mobile-nav__container */}
      <ul className="mobile-nav__contact list-unstyled">
        <li>
          <i className="fa fa-envelope" />
          <a href="mailto:needhelp@packageName__.com">
            booking@rizchauffeur.com
          </a>
        </li>
        <li>
          <i className="fa fa-phone-alt" />
          <a href="tel:666-888-0000">+971 563847412</a>
        </li>
      </ul>
      {/* /.mobile-nav__contact */}
      <div className="mobile-nav__top">
        <div className="mobile-nav__social">
          <a href="#" className="fab fa-twitter" />
          <a href="#" className="fab fa-facebook-square" />
          <a href="#" className="fab fa-pinterest-p" />
          <a href="#" className="fab fa-instagram" />
        </div>
        {/* /.mobile-nav__social */}
      </div>
      {/* /.mobile-nav__top */}
    </div>
    {/* /.mobile-nav__content */}
  </div>
  {/* /.mobile-nav__wrapper */}
  
  <div className="scroll-to-top">
    <svg className="scroll-top-inner" viewBox="-1 -1 102 102">
        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
    </svg>
</div>
</div>
    )
};
export default AirportTransfer;